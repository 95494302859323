import { Upload } from "lucide-react";
import { useRef } from "react";
import { Alert, AlertDescription } from "../../ui/alert.jsx";
import { Input } from "../../ui/input.jsx";
import { Label } from "../../ui/label.jsx";
import React from "react";

const ALLOWED_FILE_TYPES = {
  "application/pdf": ".pdf",
  "application/msword": ".doc",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    ".docx",
};

const FileUploader = React.forwardRef(({ onFileChange, error }, ref) => {
  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.type in ALLOWED_FILE_TYPES) {
      onFileChange(selectedFile);
    } else {
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      onFileChange(null);
    }
  };

  const clearFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  // Expose the clearFileInput method through the ref
  React.useImperativeHandle(ref, () => ({
    clearFileInput
  }));

  return (
    <div className="space-y-2">
      <Label htmlFor="fileInput">Upload file</Label>
      <div className="flex items-center space-x-2">
        <Input
          id="fileInput"
          ref={fileInputRef}
          type="file"
          onChange={handleFileChange}
          accept={Object.values(ALLOWED_FILE_TYPES).join(",")}
          className="file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-violet-50 file:text-violet-700 hover:file:bg-violet-100 h-16"
        />
        <Upload className="w-6 h-6 text-gray-400" />
      </div>
      {error && (
        <Alert variant="destructive">
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}
    </div>
  );
});

FileUploader.displayName = 'FileUploader';

export default FileUploader;
