import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../ui/card.jsx";
import { Input } from "../../ui/input.jsx";
import { Label } from "../../ui/label.jsx";
import { Textarea } from "../../ui/textarea.jsx";
import { Button } from "../../ui/button.jsx";
import { Alert, AlertDescription } from "../../ui/alert.jsx";
import { AlertCircle, Plus, Pencil } from "lucide-react";
import ColorSelector from "./ColorSelector";

const CourseForm = ({ course, onSubmit, onDelete, excludedColorThemes }) => {
  const [title, setTitle] = useState(course?.title || "");
  const [description, setDescription] = useState(course?.description || "");
  const [colorTheme, setColorTheme] = useState(course?.colorTheme || "#D2042D");
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(null);
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    const isValid =
      title.trim() !== "" && description.trim() !== "";
    setIsFormValid(isValid);
  }, [title, description]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isFormValid) {
      setAlert({
        type: "error",
        message: "Please fill in all required fields.",
      });
      return;
    }

    setLoading(true);
    const result = await onSubmit({ title, description, colorTheme });
    if (result?.type === "error") {
      setAlert(result);
    }
    setLoading(false);
  };

  return (
    <Card className="border-0 shadow-none">
      <CardHeader>
        <CardTitle className="flex items-center justify-left text-primary">
          {course ? (
            <Pencil className="mr-2 h-5 w-5" />
          ) : (
            <Plus className="mr-2 h-5 w-5" />
          )}{" "}
          {course ? "Edit Course" : "Create New Course"}
        </CardTitle>
        <CardDescription>
          {course
            ? "Update course details below"
            : "Fill in the details to create a new course"}
        </CardDescription>
      </CardHeader>
      <CardContent>
        <form onSubmit={handleSubmit} className="space-y-8">
          <div className="space-y-4">
            <Label htmlFor="title" className="text-md">
              Course Title
            </Label>
            <Input
              id="title"
              placeholder="Enter course title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
              className="border-secondary"
            />
          </div>
          <div className="space-y-2">
            <Label htmlFor="description" className="text-md">
              Course Description
            </Label>
            <Textarea
              id="description"
              placeholder="Enter course description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
              className="border-secondary"
            />
          </div>
          <div className="space-y-2">
            <Label className="text-md">Course Color Theme</Label>
            <ColorSelector
              selectedColor={colorTheme}
              onColorChange={setColorTheme}
              excludedColorThemes={excludedColorThemes}
            />
          </div>
          {alert && (
            <Alert variant="destructive">
              <AlertCircle className="h-4 w-4" />
              <AlertDescription>{alert.message}</AlertDescription>
            </Alert>
          )}
        </form>
      </CardContent>
      <CardFooter className="flex justify-between">
        <Button
          onClick={handleSubmit}
          disabled={loading || !isFormValid}
          className="bg-secondary text-secondary-foreground hover:bg-secondary/90"
        >
          {loading
            ? course
              ? "Updating Course..."
              : "Creating Course..."
            : course
            ? "Update course"
            : "Create New Course"}
        </Button>
        {course && (
          <Button variant="destructive" size="sm" onClick={onDelete}>
            Delete Course
          </Button>
        )}
      </CardFooter>
    </Card>
  );
};

export default CourseForm;
