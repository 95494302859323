import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { useToast } from "../../ui/use-toast";

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export const isValidEmail = (email) => {
  return email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const formatContent = (content) => {
  // Split by either *text: pattern OR * text pattern
  const parts = content.split(/(\*[^*\n]+?(?::|(?=\s*\n|\s*$)))/g);

  return parts.map((part, index) => {
    // Check for both patterns: *text: OR * text
    const matchWithColon = part.match(/^\*(.*):$/);
    const matchBulletPoint = part.match(/^\*\s*(.+?)(?=\s*$)/);

    if (matchWithColon) {
      // Handle *text: pattern
      return (
        <div key={index} className="font-bold mt-4 mb-2">
          {matchWithColon[1]}:
        </div>
      );
    } else if (matchBulletPoint) {
      // Handle bullet point pattern
      return (
        <div key={index} className="font-bold mt-4 mb-2 pl-4">
          • {matchBulletPoint[1]}
        </div>
      );
    }
    return <span key={index}>{part}</span>;
  });
};

export const formatFileSize = (bytes) => {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
};

export const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(",")[1]);
    reader.onerror = (error) => reject(error);
  });
};

export const useThrowToastError = () => {
  const { toast } = useToast();
  return (error) => {
    toast({
      variant: "destructive",
      title: "Error",
      description: `There was a server error: ${error} Please try again.`,
    });
  };
};

export const isLightColor = (hexColor) => {
  const r = parseInt(hexColor.slice(1, 3), 16);
  const g = parseInt(hexColor.slice(3, 5), 16);
  const b = parseInt(hexColor.slice(5, 7), 16);
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;
  return brightness > 128;
};

export const getLighterShade = (hex, percent) => {
  const num = parseInt(hex.replace("#", ""), 16);
  const amt = Math.round(2.55 * percent);
  const R = (num >> 16) + amt;
  const G = ((num >> 8) & 0x00ff) + amt;
  const B = (num & 0x0000ff) + amt;
  return `#${(
    (1 << 24) |
    ((R < 255 ? (R < 1 ? 0 : R) : 255) << 16) |
    ((G < 255 ? (G < 1 ? 0 : G) : 255) << 8) |
    (B < 255 ? (B < 1 ? 0 : B) : 255)
  )
    .toString(16)
    .slice(1)}`;
};

export const getArrayCharacterCount = (array) => {
  return array.reduce((acc, item) => acc + item.length, 0);
};

export function haveSameElements(arr1, arr2) {
  return (
    arr1.length === arr2.length &&
    arr1.every((item) => arr2.includes(item)) &&
    arr2.every((item) => arr1.includes(item))
  );
}

export const translateErrorMessage = (error) => {
  if (error.code) {
    switch (error.code) {
      case "auth/invalid-email":
        return "Invalid email address. Please try again.";
      case "auth/invalid-credential":
        return "Incorrect username or password. Please try again.";
      case "auth/user-disabled":
        return "This account has been disabled. Please contact support.";
      case "auth/user-not-found":
        return "No account found with this email. Please create an account.";
      case "auth/wrong-password":
        return "Incorrect password. Please try again.";
      case "auth/too-many-requests":
        return "Too many failed login attempts. Please try again later or reset your password.";
      case "auth/network-request-failed":
        return "Network error. Please check your internet connection and try again.";
      case "NO_ACCOUNT":
        return "No account found. Please create an account first.";
      case "ERR_NETWORK":
        return "Could not connect to servers. Please try again later.";
      default:
        return "An error occurred during login. Please try again.";
    }
  } else if (error.response) {
    return "Error fetching user data. Please try again.";
  } else {
    return "An unexpected error occurred. Please try again.";
  }
};
