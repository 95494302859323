export const FREE_QUESTION_LIMIT = 7;
export const FREE_CHAT_LIMIT = 3;
export const FREE_QUESTION_GENERATION_LIMIT = 6;
export const FREE_COURSE_LIMIT = 3;

export const PAID_QUESTION_GENERATION_LIMIT = 40;

export const STUDENT_YEARLY_COST = 15;

export const TEACHER_PRO_PRICE = 100;
export const CLASSROOM_PRO_PRICE = 250;

export const CLASSROOM_PRO_INCLUDED_STUDENTS = 15;

export const TEACHER_PRO_VOLUME_PRICING = [15, 12, 10];

export const TEACHER_PRO_MAX_STUDENTS = 20;

export const TEACHER_PRO_PRODUCT_ID = "prod_RXTqBWCGENzgad";

export const getMonthlyTotal = (annualCost) => {
  return Math.round((annualCost / 12) * 100) / 100;
};
export const getTeacherProTotal = (students) => {
  let volumeIndex;
  const s = students.length;

  if (s < 1) {
    return TEACHER_PRO_PRICE;
  } else if (s <= 9) {
    volumeIndex = 0;
  } else if (s >= 9 && s <= 14) {
    volumeIndex = 1;
  } else {
    volumeIndex = 2;
  }
  return TEACHER_PRO_PRICE + TEACHER_PRO_VOLUME_PRICING[volumeIndex] * s;
};
