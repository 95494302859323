import { useNavigate, useLocation } from "react-router-dom";
import { CheckCircle } from "lucide-react";
import LogoutButton from "../../components/auth/LogoutButton";

const PaymentSuccessPage = () => {
  const navigate = useNavigate();

  const { state } = useLocation();
  const { students, monthlyPayment, subscriptionId, email, planTitle } =
    state || {};

  return (
    <div className="flex flex-col items-center justify-center min-h-[calc(100vh-16rem)] px-4 py-8 max-w-2xl mx-auto">
      <CheckCircle className="w-16 h-16 text-primary mb-2" />
      <h1 className="font-bold text-4xl text-primary mb-4">
        Subscription Success!
      </h1>
      <h4 className="text-gray-600 text-lg mb-8">
        Your subscription to {planTitle} was successful.
      </h4>

      <div className="w-full border rounded-lg p-6 shadow-sm">
        <h2 className="font-bold text-2xl mb-6 text-left">Order Summary</h2>

        <div className="grid grid-cols-2 gap-8 mb-8">
          <div className="space-y-4">
            <div className="flex items-center flex-col">
              <span className="text-gray-600">Total Students:</span>
              <span className="font-medium">{students?.length || 0}</span>
            </div>
            <div className="flex items-center flex-col">
              <span className="text-gray-600">Monthly Payment:</span>
              <span className="font-medium">${monthlyPayment}/month</span>
            </div>
          </div>

          <div className="space-y-4">
            <div className="flex items-center flex-col">
              <span className="text-gray-600">Email:</span>
              <span className="font-medium">{email}</span>
            </div>
            <div className="flex items-center flex-col">
              <span className="text-gray-600">Subscription ID:</span>
              <span className="font-medium">
                {subscriptionId.split("sub_")[1]}
              </span>
            </div>
          </div>

          {students.length > 0 && (
            <div className="col-span-2">
              <h2 className="font-bold mb-2">Included Students: </h2>
              <div className="grid grid-cols-2 gap-1">
                {students.map((student) => (
                  <p key={student} className="text-gray-600 p-2">
                    {student}
                  </p>
                ))}
              </div>
            </div>
          )}
        </div>

        <div className="space-y-3 text-center text-gray-600 text-sm mb-8">
          <p>
            Thank you for your purchase! If you have any questions or concerns,
            please reach out to{" "}
            <a
              href="mailto:jake@AilaEducation.com"
              className="text-primary hover:underline"
            >
              jake@AilaEducation.com
            </a>
          </p>
          <p>
            You can cancel your subscription at any time through your account
            management page.
          </p>
        </div>
        <div className="flex flex-col items-center justify-center gap-4">
          <LogoutButton className={"w-1/3 bg-green-300 hover:bg-green-100"} />
          <p>
            For your upgrade to take effect, please logout and log back in.{" "}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccessPage;
