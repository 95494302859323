import { useState } from "react";
import { Button } from "../../ui/button.jsx";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";
import CancelButton from "../PaymentPageComponents/CancelButton.js";
import { getArrayCharacterCount } from "../utils/utils.js";
import api from "../../../api";
import { useUser } from "../../../Contexts/userContext";

const PaymentForm = ({
  monthlyPayment,
  planTitle,
  students,
  email,
  subscriptionId,
}) => {
  const stripe = useStripe();
  const navigate = useNavigate();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const { user } = useUser();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements || processing) {
      return;
    }

    setProcessing(true);

    try {
      const { error: submitError, paymentIntent } = await stripe.confirmPayment(
        {
          elements,
          redirect: "if_required",
          confirmParams: {
            receipt_email: email,
            payment_method_data: {
              metadata: {
                student_count: students.length,
                total_amount: monthlyPayment,
                plan_title: planTitle,
                students:
                  getArrayCharacterCount(students) < 500 &&
                  JSON.stringify(students),
              },
            },
          },
        }
      );

      if (paymentIntent?.status === "succeeded") {
        await api.post(`/users/${user.uid}/subscribe`, {
          subscriptionId,
          students: JSON.stringify(students),
          planTitle,
          monthlyPayment,
        });

        if (students.length > 0) {
          try {
            await api.post("/users/subscribe-students", {
              subscriptionId,
              students: JSON.stringify(students),
              planTitle,
              subscriptionOwner: user.uid,
            });
          } catch (error) {
            console.error("Error subbing students: ", error);
          }
        }

        return navigate("/payment/success", {
          state: {
            students: students,
            monthlyPayment,
            subscriptionId,
            email: paymentIntent.receipt_email,
            planTitle: planTitle,
          },
        });
      }

      if (submitError) {
        console.error(submitError);
        setError(submitError.message);
        setProcessing(false);
        return;
      }

      if (paymentIntent === null) {
        setProcessing(true);
        return;
      }
    } catch (err) {
      console.error("ERROR", err);
      setError("An unexpected error occurred.");
      setProcessing(false);
    }
  };
  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <PaymentElement />
      {error && <div className="text-red-500 text-sm mt-2">{error}</div>}
      <div className="flex justify-between">
        <Button
          type="submit"
          disabled={!stripe || processing}
          className="bg-primary text-white py-2 px-10 rounded-lg hover:bg-primary/90 disabled:opacity-50"
        >
          {processing
            ? "Processing..."
            : `Subscribe for $${monthlyPayment}/month`}
        </Button>
        <CancelButton />
      </div>
    </form>
  );
};

export default PaymentForm;
