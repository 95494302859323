import { FileText } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../api";
import { Button } from "../../ui/button.jsx";
import { Card, CardContent, CardHeader, CardTitle } from "../../ui/card.jsx";

import { useUser } from "../../../Contexts/userContext";
import { Toaster } from "../../ui/toaster.jsx";
import { useToast } from "../../ui/use-toast";
import QuestionGenerator from "../QuestionGenerator/QuestionGenerator";
import AssignmentCreationDialog from "./AssignmentCreationDialog";
import AssignmentForm from "./AssignmentForm";
import CourseDropdown from "./CourseDropdown";
import QuestionList from "./QuestionCreation/QuestionList";
import { SkeletonLoader } from "./Skeleton";

const AssignmentCreator = () => {
  const { assignmentId } = useParams();
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [botPermissions, setBotPermissions] = useState(true);
  const [questions, setQuestions] = useState([]);
  const [courseIds, setCourseIds] = useState([]);
  const [totalPoints, setTotalPoints] = useState(0);
  const [submitting, setSubmitting] = useState(false);
  const [sendingData, setSendingData] = useState(false);
  const [loading, setLoading] = useState(true);
  const { toast } = useToast();
  const { refreshUserInfo, user } = useUser();
  const paid = user.paid;

  useEffect(() => {
    const fetchAssignment = async () => {
      if (assignmentId) {
        try {
          const response = await api.get(`/assignments/${assignmentId}`);
          const fetchedAssignment = response.data;
          setDueDate(fetchedAssignment.dueDate);
          setDescription(fetchedAssignment.description);
          setTitle(fetchedAssignment.title);
          setQuestions(fetchedAssignment.questions);
          setLoading(false);
        } catch (error) {
          console.error("Error fetching assignment data:", error);
          setLoading(false);
        }
      }
      setLoading(false);
    };

    fetchAssignment();
  }, [assignmentId]);

  useEffect(() => {
    setTotalPoints(calculateTotalPoints(questions));
  }, [questions]);

  const calculateTotalPoints = (questions) => {
    return questions.reduce((total, question) => {
      const points = parseInt(question.points);
      return !isNaN(points) ? total + points : total;
    }, 0);
  };

  function createToast() {
    toast({
      title: "Success!",
      description: `Your assignment has been ${
        assignmentId ? "updated" : "created"
      } successfully.`,
      duration: 3000,
    });
  }

  const handleSubmit = async () => {
    setSendingData(true);
    const assignment = {
      title,
      description,
      dueDate,
      questions,
      totalPoints,
      botPermissions,
    };
    try {
      if (assignmentId) {
        await api.put(`/assignments/${assignmentId}`, {
          assignment,
          courseIds,
        });
      } else {
        await api.post(`/assignments`, { assignment, courseIds });
      }

      setTitle("");
      setDescription("");
      setQuestions([]);
      setCourseIds([]);
      setDueDate("");
      setTotalPoints(0);

      await refreshUserInfo();

      if (assignmentId) {
        navigate(-1);
      }

      createToast();
    } catch (error) {
      console.error("Error submitting assignment:", error);
    } finally {
      setSendingData(false);
      setSubmitting(false);
    }
  };

  const isFormValid = useMemo(() => {
    return (
      title.trim() !== "" &&
      description.trim() !== "" &&
      dueDate !== "" &&
      courseIds.length > 0 &&
      questions.length > 0 &&
      questions.every((q) => q.text.trim() !== "" && q.points !== "")
    );
  }, [title, description, dueDate, courseIds, questions]);

  const handleSelectedCourseIdsChange = (selectedCourseIds) => {
    setCourseIds(selectedCourseIds);
  };

  return (
    <>
      <Card className="w-full mx-auto shadow-lg rounded-lg overflow-hidden">
        <CardHeader className="bg-primary text-primary-foreground p-6">
          <CardTitle className="flex items-center text-2xl font-bold">
            <FileText className="mr-2 h-6 w-6" />
            {assignmentId ? "Edit Assignment" : "Create Assignment"}
          </CardTitle>
        </CardHeader>
        <CardContent className="p-6 space-y-6">
          {loading ? (
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
              <div className="lg:col-span-2">
                <SkeletonLoader />
              </div>
              <div>
                <SkeletonLoader />
              </div>
            </div>
          ) : (
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
              <div className="lg:col-span-2 space-y-6">
                <Card className="bg-card">
                  <CardHeader>
                    <CardTitle className="text-primary">
                      Assignment Details
                    </CardTitle>
                  </CardHeader>
                  <CardContent>
                    <AssignmentForm
                      title={title}
                      setTitle={setTitle}
                      description={description}
                      setDescription={setDescription}
                      dueDate={dueDate}
                      setDueDate={setDueDate}
                      botPermissions={botPermissions}
                      setBotPermissions={setBotPermissions}
                    />
                  </CardContent>
                </Card>

                <Card className="bg-card">
                  <CardHeader>
                    <CardTitle className="text-primary">Questions</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <QuestionList
                      questions={questions}
                      setQuestions={setQuestions}
                      assignmentBotPermissions={botPermissions}
                      limit={!paid}
                    />
                  </CardContent>
                </Card>
              </div>

              <div className="space-y-6">
                <Card className="bg-card">
                  <CardHeader>
                    <CardTitle className="text-primary">
                      Course Selection
                    </CardTitle>
                  </CardHeader>
                  <CardContent>
                    <CourseDropdown
                      onSelectedCourseIdsChange={handleSelectedCourseIdsChange}
                    />
                  </CardContent>
                </Card>

                <Card className="bg-card">
                  <CardHeader>
                    <CardTitle className="text-primary">
                      Question Generator
                    </CardTitle>
                  </CardHeader>
                  <CardContent>
                    <QuestionGenerator
                      questions={questions}
                      setQuestions={setQuestions}
                      assignmentBotPermissions={botPermissions}
                    />
                  </CardContent>
                </Card>
              </div>
            </div>
          )}

          {!loading && (
            <div className="flex items-center justify-between mt-6">
              <p className="text-lg font-semibold text-primary">
                Total Points: {totalPoints}
              </p>
              <Button
                variant="default"
                size="lg"
                className="bg-primary hover:bg-primary/90 text-primary-foreground"
                disabled={!isFormValid || submitting || sendingData}
                onClick={() => setSubmitting(true)}
              >
                {assignmentId ? "Update Assignment" : "Create Assignment"}
              </Button>
            </div>
          )}
        </CardContent>
        <Toaster />
      </Card>
      {!loading && (
        <AssignmentCreationDialog
          isOpen={submitting || sendingData}
          onOpenChange={setSubmitting}
          handleSubmit={handleSubmit}
          loading={sendingData}
          updateInstance={assignmentId}
        />
      )}
    </>
  );
};

export default AssignmentCreator;
