import { Label } from "../../../ui/label.jsx";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../ui/select.jsx";

const Type = ({ type, onChange }) => {
  return (
    <div className="space-y-2 pb-4">
      <Label htmlFor="type">Question Type:</Label>
      <Select onValueChange={onChange} value={type}>
        <SelectTrigger className="w-full">
          <SelectValue placeholder="Select question type" />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="short-answer">Short Answer</SelectItem>
          <SelectItem value="essay">Essay</SelectItem>
          <SelectItem value="multiple-choice">Multiple Choice</SelectItem>
          <SelectItem value="select-all">Select All</SelectItem>
          {/* <SelectItem value="coding">Coding</SelectItem> */}
          {/* <SelectItem value="file-upload">File Upload</SelectItem> */}
        </SelectContent>
      </Select>
    </div>
  );
};

export default Type;
