import { useMemo } from "react";
import { useParams } from "react-router-dom";
import BaseLayout from "../../components/layout/BaseLayout";
import TeacherCourseHeader from "../../components/layout/teacher/CourseHeader";
import AssignmentCreator from "../../components/teacher/AssignmentCreation/AssignmentCreator";
import CourseAssignments from "../../components/teacher/Lists/CourseAssignments";
import CourseRoster from "../../components/teacher/Lists/Rosters/CourseRoster";
import { useUser } from "../../Contexts/userContext";
import { useCourseData } from "../../hooks/useCourseData";
import BackHomeButton from "../../components/shared/utils/NavigateHomeButton";

const TeacherCourseView = () => {
  const { courseId } = useParams();
  const { courses, loading, error } = useUser();
  const { courseAssignments } = useCourseData(courseId);

  const course = useMemo(
    () => courses.find((course) => course.id === courseId),
    [courses, courseId]
  );

  return (
    <BaseLayout>
      <div className="container mx-auto px-4 py-8 space-y-8">
        <div className="flex-grow">
          <BackHomeButton />
          <TeacherCourseHeader course={course} loading={loading} />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <CourseAssignments
            course={course}
            assignments={courseAssignments}
            loading={loading}
            error={error}
          />
          <CourseRoster course={course} loading={loading} />
        </div>

        <AssignmentCreator />
      </div>
    </BaseLayout>
  );
};

export default TeacherCourseView;
