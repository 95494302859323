import React, { useState, useEffect } from "react";
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogFooter,
} from "../../ui/alert-dialog.jsx";
import { Button } from "../../ui/button.jsx";
import LogoutButton from "../../auth/LogoutButton";
import { useUser } from "../../../Contexts/userContext";

const CancellationPopup = ({ isOpen, onClose }) => {
  const [countdown, setCountdown] = useState(10);
  const { logout } = useUser();

  useEffect(() => {
    if (isOpen && countdown > 0) {
      const timer = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);

      return () => clearInterval(timer);
    }
    if (isOpen && countdown < 1) {
      logout();
    }
  }, [isOpen, countdown]);

  return (
    <AlertDialog open={isOpen} onOpenChange={onClose}>
      <AlertDialogContent className="sm:max-w-md">
        <AlertDialogHeader>
          <AlertDialogTitle className="text-center text-2xl text-primary">
            Subscription Cancelled Successfully
          </AlertDialogTitle>
          <AlertDialogDescription className="text-center space-y-4">
            <p className="text-primary text-md">
              Your subscription has been cancelled. Thank you for being a
              subscriber!
            </p>
            <p className="font-bold text-md">
              For security and proper cancellation, you will be logged out.{" "}
            </p>
            <p className="text-lg font-semibold">
              Logging out in {countdown} seconds
            </p>
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter className="flex justify-center">
          <LogoutButton className="w-full" />
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default CancellationPopup;
