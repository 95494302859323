import { useLocation, useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import BaseLayout from "../../components/layout/BaseLayout";
import { Card, CardHeader, CardContent } from "../../components/ui/card";
import { Button } from "../../components/ui/button";
import { haveSameElements } from "../../components/shared/utils/utils";
import CancellationPopup from "../../components/shared/Profile/CancellationPopup";
import api from "../../api";
import {
  getMonthlyTotal,
  getTeacherProTotal,
  TEACHER_PRO_PRODUCT_ID,
} from "../../components/shared/utils/PricesAndLimits";
import { SubscriptionDetails } from "../../components/shared/SubscriptionManagement/PageComponents";
import { CurrentStudentsList } from "../../components/shared/SubscriptionManagement/PageComponents";
import { SubscriptionAlert } from "../../components/shared/SubscriptionManagement/PageComponents";
import ConfirmationDialog from "../../components/shared/SubscriptionManagement/ConfirmationDialog";
import { StudentManagement } from "../../components/shared/SubscriptionManagement/PageComponents";
import NavigateButton from "../../components/shared/utils/NavigateButton";

const SubscriptionManagementPage = () => {
  const { state } = useLocation();
  const { userId } = useParams();
  const navigate = useNavigate();

  const [currentStudents, setCurrentStudents] = useState(state.students);
  const [currentMonthlyPayment, setCurrentMonthlyPayment] = useState(
    state.monthlyPayment
  );
  const [newStudentArray, setNewStudentArray] = useState(state.students);
  const [loading, setLoading] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [newMonthlyPayment, setNewMonthlyPayment] = useState(
    state.monthlyPayment
  );
  const [cancelling, setCancelling] = useState(false);
  const [confirmCancel, setConfirmCancel] = useState(false);
  const [showCancellationPopup, setShowCancellationPopup] = useState(false);

  const getStudentChanges = () => {
    const addedStudents = newStudentArray.filter(
      (student) => !currentStudents.includes(student)
    );
    const removedStudents = currentStudents.filter(
      (student) => !newStudentArray.includes(student)
    );
    return { addedStudents, removedStudents };
  };

  useEffect(() => {
    setNewMonthlyPayment(getMonthlyTotal(getTeacherProTotal(newStudentArray)));
  }, [newStudentArray]);

  const handleCancelSubscription = async () => {
    if (confirmCancel) {
      try {
        setCancelling(true);
        await api.post(`/users/${userId}/cancel-subscription`, {
          subscriptionId: state.subscriptionId,
          students: JSON.stringify(newStudentArray),
        });
        setShowCancellationPopup(true);
      } catch (error) {
        console.error("Error cancelling subscription:", error);
      } finally {
        setCancelling(false);
      }
    } else {
      setConfirmCancel(true);
    }
  };

  const handleUpdate = async () => {
    const { removedStudents } = getStudentChanges();
    try {
      setLoading(true);
      await api.post("/update-subscription", {
        subscriptionId: state.subscriptionId,
        total_amount: newMonthlyPayment,
        product_id: TEACHER_PRO_PRODUCT_ID,
        planTitle: state.planTitle,
        students: JSON.stringify(newStudentArray),
        userId,
      });

      await api.post(`/users/${userId}/subscribe`, {
        subscriptionId: state.subscriptionId,
        students: JSON.stringify(newStudentArray),
        planTitle: state.planTitle,
        monthlyPayment: newMonthlyPayment,
      });

      if (removedStudents.length > 0) {
        await api.post("/users/unsubscribe-students", {
          students: JSON.stringify(removedStudents),
        });
      }

      // Update the current values after successful API calls
      setCurrentStudents(newStudentArray);
      setCurrentMonthlyPayment(newMonthlyPayment);

      setUpdateSuccess(true);
      setTimeout(() => setUpdateSuccess(false), 5000);
    } catch (error) {
      console.error("Error updating: ", error);
    } finally {
      setLoading(false);
      setShowConfirmDialog(false);
    }
  };

  const { addedStudents, removedStudents } = getStudentChanges();
  const hasChanges = !haveSameElements(currentStudents, newStudentArray);

  return (
    <BaseLayout>
      <div className="max-w-4xl mx-auto p-4 flex">
        <NavigateButton url={`/profile/${userId}`} />
        <Card className="shadow-lg">
          <CardHeader>
            <h1 className="text-3xl font-bold text-primary text-center">
              Manage Your Subscription
            </h1>
          </CardHeader>
          <CardContent className="space-y-6">
            <div className="grid md:grid-cols-2 gap-6">
              <SubscriptionDetails
                planTitle={state.planTitle}
                monthlyPayment={currentMonthlyPayment}
                creationDate={state.creationDate}
                subscriptionId={state.subscriptionId.split("sub_")[1]}
              />
              <CancellationPopup
                isOpen={showCancellationPopup}
                onClose={() => setShowCancellationPopup(false)}
              />
              <CurrentStudentsList students={currentStudents} />
            </div>

            <StudentManagement
              newStudentArray={newStudentArray}
              setNewStudentArray={setNewStudentArray}
              newMonthlyPayment={newMonthlyPayment}
            />

            <SubscriptionAlert show={updateSuccess} />

            <div className="flex justify-between items-center pt-4">
              <Button
                variant={confirmCancel ? "destructive" : "outline"}
                onClick={handleCancelSubscription}
                disabled={loading || cancelling}
                className="w-full md:w-auto mr-2"
              >
                {cancelling
                  ? "Cancelling..."
                  : confirmCancel
                  ? "Confirm Cancellation?"
                  : "Cancel Subscription"}
              </Button>
              <Button
                onClick={() => setShowConfirmDialog(true)}
                disabled={!hasChanges || loading}
                className="w-full md:w-auto"
              >
                {loading ? "Updating..." : "Update Subscription"}
              </Button>
            </div>
          </CardContent>
        </Card>

        <ConfirmationDialog
          open={showConfirmDialog}
          onOpenChange={setShowConfirmDialog}
          addedStudents={addedStudents}
          removedStudents={removedStudents}
          newMonthlyPayment={newMonthlyPayment}
          onConfirm={handleUpdate}
        />
      </div>
    </BaseLayout>
  );
};

export default SubscriptionManagementPage;
