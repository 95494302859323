import React from "react";
import FreePlanCard from "../components/shared/PlanCards/FreePlanCard";
import ClassroomProCard from "../components/shared/PlanCards/ClassroomProCard";
import EnterpriseCard from "../components/shared/PlanCards/EnterpriseCard";
import TeacherProCard from "../components/shared/PlanCards/TeacherPro";
import BaseLayout from "../components/layout/BaseLayout";
import { useUser } from "../Contexts/userContext";
import FAQAccordion from "../components/shared/PlanCards/FAQAccordion";

const PlansPage = () => {
  const { user } = useUser();
  const paid = user.paid;
  return (
    <BaseLayout>
      <div className="container mx-auto px-4 py-12">
        {/* Header Section */}
        <div className="text-center mb-4">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            Choose Your Plan
          </h1>
          <p className="text-lg text-gray-600">
            Select the perfect plan for your educational needs
          </p>
        </div>
        <div className="flex justify-center mb-6">
          <p className="text-md text-primary">
            Note: All prices include taxes and fees (no hidden costs)
          </p>
        </div>
        {/* Plans Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <div className="h-full">
            <FreePlanCard highlight={!paid} />
          </div>
          <div className="h-full">
            <TeacherProCard />
          </div>
          {/* <div className="h-full">
            <ClassroomProCard />
          </div> */}
          <div className="h-full">
            <EnterpriseCard />
          </div>
        </div>

        {/* Compare Plans Button
        <div className="text-center mt-12">
          <button className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
            Compare All Plans
          </button>
        </div> */}
        <FAQAccordion />
      </div>
    </BaseLayout>
  );
};

export default PlansPage;
