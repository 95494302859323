import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../../ui/alert-dialog.jsx";

const ConfirmationDialog = ({
  open,
  onOpenChange,
  addedStudents,
  removedStudents,
  newMonthlyPayment,
  onConfirm,
}) => (
  <AlertDialog open={open} onOpenChange={onOpenChange}>
    <AlertDialogContent>
      <AlertDialogHeader>
        <AlertDialogTitle>Confirm Subscription Changes</AlertDialogTitle>
        <AlertDialogDescription>
          <div className="space-y-4 py-4">
            {addedStudents.length > 0 && (
              <div>
                <h3 className="font-medium text-green-600 mb-2">
                  Students to Add:
                </h3>
                <ul className="list-disc pl-5 space-y-1">
                  {addedStudents.map((student, index) => (
                    <li key={index} className="text-sm">
                      {student}
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {removedStudents.length > 0 && (
              <div>
                <h3 className="font-medium text-red-600 mb-2">
                  Students to Remove:
                </h3>
                <ul className="list-disc pl-5 space-y-1">
                  {removedStudents.map((student, index) => (
                    <li key={index} className="text-sm">
                      {student}
                    </li>
                  ))}
                </ul>
              </div>
            )}

            <div className="pt-4 border-t">
              <div className="flex justify-between items-center">
                <span>New Monthly Total:</span>
                <span className="font-bold text-lg">${newMonthlyPayment}</span>
              </div>
            </div>
          </div>
        </AlertDialogDescription>
      </AlertDialogHeader>
      <AlertDialogFooter>
        <AlertDialogCancel>Cancel</AlertDialogCancel>
        <AlertDialogAction onClick={onConfirm}>
          Confirm Changes
        </AlertDialogAction>
      </AlertDialogFooter>
    </AlertDialogContent>
  </AlertDialog>
);

export default ConfirmationDialog;
