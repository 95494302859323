import { createContext, useCallback, useContext } from "react";
import useAuth from "../hooks/useAuth";
import useUserInfo from "./useUserInfo";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const {
    user,
    loading: authLoading,
    login,
    signup,
    logout: authLogout,
    googleSignup,
    googleLogin
  } = useAuth();

  const {
    courses,
    assignments,
    submissions,
    loading: dataLoading,
    error,
    refreshUserInfo,
    updateSubmissions,
    resetUserInfo,
  } = useUserInfo(user);

  const logout = useCallback(async () => {
    await authLogout();
    resetUserInfo();
  }, [authLogout, resetUserInfo]);

  const value = {
    user,
    courses,
    assignments,
    submissions,
    loading: authLoading || dataLoading,
    error,
    login,
    signup,
    logout,
    googleSignup,
    googleLogin, 
    refreshUserInfo,
    updateSubmissions,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};
