import React, { useState } from "react";
import { Input } from "../../ui/input.jsx";
import { Button } from "../../ui/button.jsx";
import { X } from "lucide-react";
import { isValidEmail } from "../utils/utils.js";
import { TEACHER_PRO_MAX_STUDENTS } from "../utils/PricesAndLimits.js";

const StudentEmailList = ({ students, setStudents, disabled }) => {
  const [currentEmail, setCurrentEmail] = useState("");
  const [error, setError] = useState(null);

  const handleAddEmail = () => {
    if (
      currentEmail &&
      isValidEmail(currentEmail) &&
      !students.includes(currentEmail)
    ) {
      setStudents([...students, currentEmail]);
      setCurrentEmail("");
    } else if (students.includes(currentEmail)) {
      setError("Email address has already been added.");
      setTimeout(() => {
        setError(null);
      }, [3000]);
    } else if (!isValidEmail(currentEmail)) {
      setError("Invalid email address.");
      setTimeout(() => {
        setError(null);
      }, [3000]);
    } else {
      setError("Please enter a proper email address");
      setTimeout(() => {
        setError(null);
      }, [3000]);
    }
  };

  const handleRemoveEmail = (emailToRemove) => {
    setStudents(students.filter((email) => email !== emailToRemove));
  };

  return (
    <div className="space-y-4">
      <div className="flex gap-2">
        <Input
          id="emailInput"
          type="email"
          value={currentEmail}
          onChange={(e) => setCurrentEmail(e.target.value)}
          placeholder="Enter student email"
          className="flex-grow"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              handleAddEmail();
            }
          }}
          disabled={disabled}
        />
        <Button
          id="addBtn"
          type="button"
          disabled={!currentEmail || disabled}
          onClick={handleAddEmail}
        >
          Add
        </Button>
      </div>
      <div className="mt-2">
        <p className="text-red-500">{error}</p>
      </div>

      <div className="flex flex-wrap gap-2">
        {students.map((email) => (
          <div
            key={email}
            className="group flex items-center gap-2 px-3 py-1 bg-gray-100 rounded-full"
          >
            <span className="text-sm text-primary">{email}</span>
            <button
              type="button"
              onClick={() => handleRemoveEmail(email)}
              className="opacity-0 group-hover:opacity-100 transition-opacity"
              aria-label="Remove email"
            >
              <X className="h-4 w-4 text-gray-500 hover:text-gray-700" />
            </button>
          </div>
        ))}
      </div>
      {students.length >= TEACHER_PRO_MAX_STUDENTS && (
        <p className="text-red-600">Student Limit Reached</p>
      )}
    </div>
  );
};

export default StudentEmailList;
