export const formatTimestamp = (timestamp) => {
  const date = new Date(timestamp);
  const options = {
    month: "long",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };
  return date.toLocaleString("en-US", options);
};

export function generateTimestamp() {
  const now = new Date();
  return now.getTime();
}

export function getSimpleDate(timestamp) {
  if (timestamp) {
    const milliseconds =
      timestamp.toString().length < 13 ? timestamp * 1000 : timestamp;
    const date = new Date(milliseconds);

    const day = date.getDate();
    const ordinal = getOrdinalSuffix(day);

    const month = date.toLocaleString("en-US", { month: "long" });
    const year = date.getFullYear();

    return `${month} ${day}${ordinal}, ${year}`;
  }
}

function getOrdinalSuffix(day) {
  if (day > 3 && day < 21) return "th";
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}

export function convertToDate(milliseconds) {
  const date = new Date(milliseconds);
  return date.toLocaleString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "2-digit",
    timeZoneName: undefined,
    hour12: true,
  });
}
