import React from "react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../ui/accordion.jsx";
import { Card, CardHeader, CardTitle, CardContent } from "../../ui/card.jsx";
import { InfoIcon } from "lucide-react";

const FAQAccordion = () => {
  const itemClass =
    "border rounded-lg px-4 hover:bg-accent/50 transition-colors";

  return (
    <Card className="w-full mt-4">
      <CardHeader className="space-y-1">
        <CardTitle className="text-2xl font-bold">Common FAQs</CardTitle>
        <div className="flex items-start gap-2 text-muted-foreground">
          <InfoIcon className="h-5 w-5 mt-0.5 flex-shrink-0" />
          <p className="text-sm">
            The following FAQs pertain only to the Teacher Pro plan. For
            enterprise questions, please contact sales.
          </p>
        </div>
      </CardHeader>

      <CardContent>
        <Accordion type="single" collapsible className="w-full space-y-2">
          <AccordionItem value="item-0" className={itemClass}>
            <AccordionTrigger className="text-base font-medium py-4">
              Why should I include students in my plan?
            </AccordionTrigger>
            <AccordionContent className="text-muted-foreground pb-4">
              Adding students to your plan provides them with unlimited access
              to chat with Sparky, our AI tutor, enhancing their learning
              experience. While students can still join your courses and
              complete assignments without being added to your plan, they'll
              have limited chat interactions under the free tier. The
              flexibility to add or remove students anytime lets you adjust
              based on your needs and which students would benefit most from
              unlimited AI tutoring support.
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-1" className={itemClass}>
            <AccordionTrigger className="text-base font-medium py-4">
              How will I be billed?
            </AccordionTrigger>
            <AccordionContent className="text-muted-foreground pb-4">
              We accept credit card payments through the secure third-party
              platform <span className="italic">Stripe</span>. You will be
              charged monthly for your subscription, allowing you to cancel at
              any time.
            </AccordionContent>
          </AccordionItem>

          <AccordionItem value="item-2" className={itemClass}>
            <AccordionTrigger className="text-base font-medium py-4">
              What if I want to add or remove students?
            </AccordionTrigger>
            <AccordionContent className="text-muted-foreground pb-4">
              You can easily add or remove students in your account page. Your
              monthly billing will be adjusted accordingly.
            </AccordionContent>
          </AccordionItem>

          <AccordionItem value="item-3" className={itemClass}>
            <AccordionTrigger className="text-base font-medium py-4">
              Can students upgrade to premium themselves?
            </AccordionTrigger>
            <AccordionContent className="text-muted-foreground pb-4">
              No, students cannot access the "Upgrade" page on their accounts.
              We require teachers or schools to upgrade student accounts to
              maintain safety and equal access.
            </AccordionContent>
          </AccordionItem>

          <AccordionItem value="item-4" className={itemClass}>
            <AccordionTrigger className="text-base font-medium py-4">
              How are students added?
            </AccordionTrigger>
            <AccordionContent className="text-muted-foreground pb-4">
              Students get premium access when you enter their email addresses
              during checkout. This works for both existing accounts and new
              students who haven't signed up yet. You can always add more
              students after your initial purchase in your account management
              page.
            </AccordionContent>
          </AccordionItem>

          <AccordionItem value="item-5" className={itemClass}>
            <AccordionTrigger className="text-base font-medium py-4">
              Can I cancel at any time?
            </AccordionTrigger>
            <AccordionContent className="text-muted-foreground pb-4">
              Yes, you can cancel at any time through your account management
              page.
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-6" className={itemClass}>
            <AccordionTrigger className="text-base font-medium py-4">
              What if I still have more questions?
            </AccordionTrigger>
            <AccordionContent className="text-muted-foreground pb-4">
              No worries! Please contact me at{" "}
              <a
                href="mailto:jake@AilaEducation.com"
                className="text-primary hover:underline"
              >
                jake@AilaEducation.com
              </a>{" "}
              and I will respond within 48 hours.
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </CardContent>
    </Card>
  );
};

export default FAQAccordion;
