const SubscriptionHeader = ({ plan }) => {
  return (
    <div className="text-center mb-8">
      <h1 className="text-3xl font-bold text-primary mb-3">
        {plan === "TEACHER-PRO"
          ? "Upgrade to Teacher Pro"
          : "Upgrade to Classroom Pro"}
      </h1>
      <p className="text-lg text-gray-600">
        Please complete the form below to proceed with your subscription upgrade
      </p>
    </div>
  );
};

export default SubscriptionHeader;
