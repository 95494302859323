import { Bot } from "lucide-react";
import { Card, CardContent, CardHeader, CardTitle } from "../../ui/card.jsx";
import ChatComponent from "../ChatBoxComponents/ChatComponent";

const ChatCard = ({ currentQuestion, chatLogId, saving, submitting }) => {
  return (
    <Card className="w-1/3 bg-white shadow-sm h-full overflow-hidden">
      <CardHeader className="h-[10%]">
        <CardTitle className="flex items-center font-semibold text-primary">
          <Bot className="h-8 w-8" /> Sparky
        </CardTitle>
      </CardHeader>
      <CardContent className="h-[90%] p-0">
        <ChatComponent
          chatLogId={chatLogId}
          question={currentQuestion}
          exiting={saving || submitting}
        />
      </CardContent>
    </Card>
  );
};

export default ChatCard;
