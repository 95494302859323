import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../../Contexts/userContext";
import api from "../../../api";
import { Dialog, DialogContent, DialogTrigger } from "../../ui/dialog.jsx";
import { Button } from "../../ui/button.jsx";
import { Plus, Pencil, Info } from "lucide-react";
import CourseForm from "./CourseForm";
import SuccessDialog from "./SubDialogs/SuccessDialog";
import DeleteConfirmationDialog from "./SubDialogs/DeleteConfirmationDialog";
import DeletionSuccessDialog from "./SubDialogs/DeletionSuccessDialog";
import generateEnrollmentCode from "./EnrollmentCodeGenerator";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../ui/tooltip.jsx";
import { FREE_COURSE_LIMIT } from "../../shared/utils/PricesAndLimits";

const CourseManagerPopup = ({ onAction, course }) => {
  const { user, courses } = useUser();
  const [isOpen, setIsOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [showDeleteWindow, setShowDeleteWindow] = useState(false);
  const [showConfirmationWindow, setShowConfirmationWindow] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const navigate = useNavigate();
  const courseLimitReached = !user.paid && courses.length >= FREE_COURSE_LIMIT;
  const remainingCourses = user.paid ? "∞" : FREE_COURSE_LIMIT - courses.length;

  const handleSubmit = async (formData) => {
    const { title, description, colorTheme } = formData;
    const enrollmentCode = generateEnrollmentCode();
    const instructorName = user.lastName;

    try {
      if (course) {
        await api.put(`/courses/${course.id}`, {
          title,
          description,
          colorTheme,
        });
      } else {
        await api.post("/courses", {
          title,
          description,
          colorTheme,
          user,
          enrollmentCode,
          instructorName,
        });
      }
      await onAction();
      setIsSuccess(true);
      setIsOpen(false);
    } catch (error) {
      console.error(error);
      return {
        type: "error",
        message: "An error occurred. Please try again.",
      };
    }
  };

  const handleDelete = async () => {
    setDeleting(true);
    const studentIds = course.enrolledStudents.map(
      (ref) => ref._path.segments[1]
    );
    const parsedIds = JSON.stringify(studentIds);
    const ownerId = course.owner;
    try {
      await api.delete(`/courses/${course.id}`, {
        data: {
          userId: ownerId,
          roster: parsedIds,
        },
      });
    } catch (error) {
      console.error("Error deleting course, ", error);
    }
    setShowConfirmationWindow(true);
    setShowDeleteWindow(false);
    setDeleting(false);
  };

  const handleNavigate = async () => {
    await onAction();
    navigate("/homepage");
  };

  return (
    <>
      <TooltipProvider>
        <Dialog open={isOpen} onOpenChange={(open) => setIsOpen(open)}>
          <div className="flex justify-center items-center gap-3">
            <DialogTrigger asChild>
              <Button
                className="bg-primary text-primary-foreground hover:bg-primary/90"
                disabled={courseLimitReached && !course}
              >
                {course ? (
                  <Pencil className="mr-2 h-4 w-4" />
                ) : (
                  <Plus className="mr-2 h-4 w-4" />
                )}{" "}
                {course ? "Edit Course" : "Create Course"}
              </Button>
            </DialogTrigger>

            {!course && !user.paid && (
              <Tooltip>
                <TooltipTrigger asChild>
                  <div className="flex items-center text-sm text-gray-500">
                    <Info className="h-4 w-4 mr-1" />
                    {remainingCourses} course{remainingCourses !== 1 && "s"}{" "}
                    remaining
                  </div>
                </TooltipTrigger>
                <TooltipContent>
                  <p>
                    Free accounts can create up to {FREE_COURSE_LIMIT} courses.
                  </p>
                  <p className="text-xs mt-1">
                    Need more? Consider upgrading to premium.
                  </p>
                </TooltipContent>
              </Tooltip>
            )}
          </div>
          <DialogContent className="sm:max-w-[600px]">
            {!isSuccess && (
              <CourseForm
                course={course}
                onSubmit={handleSubmit}
                onDelete={() => setShowDeleteWindow(true)}
                excludedColorThemes={courses.map((c) => c.colorTheme)}
              />
            )}
          </DialogContent>
        </Dialog>
      </TooltipProvider>

      <DeleteConfirmationDialog
        isOpen={showDeleteWindow}
        onClose={() => setShowDeleteWindow(false)}
        onConfirm={handleDelete}
        isDeleting={deleting}
      />

      <DeletionSuccessDialog
        isOpen={showConfirmationWindow}
        onClose={handleNavigate}
      />

      <SuccessDialog
        isOpen={isSuccess}
        onClose={() => setIsSuccess(false)}
        title={
          course
            ? "Course updated successfully!"
            : "Course Created Successfully!"
        }
        message={
          course ? "" : `Your new course has been added to your dashboard.`
        }
      />
    </>
  );
};

export default CourseManagerPopup;
