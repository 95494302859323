import { AlertCircle, Users } from "lucide-react";
import { useEffect, useState } from "react";
import api from "../../../../api";
import { Alert, AlertDescription, AlertTitle } from "../../../ui/alert.jsx";
import { Card, CardContent, CardHeader, CardTitle } from "../../../ui/card.jsx";
import RosterSkeleton from "./RosterSkeleton";
import RosterTable from "./RosterTable";
import { Button } from "../../../ui/button.jsx";
import RosterManager from "./RosterManager";
import { useUser } from "../../../../Contexts/userContext";
import useUserInfo from "../../../../Contexts/useUserInfo";

const CourseRoster = ({ course, courseLoading }) => {
  const [loading, setLoading] = useState(true);
  const [roster, setRoster] = useState([]);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const { refreshUserInfo } = useUser();
  const { rosters } = useUserInfo();

  const handleDeleteStudents = async (studentIds) => {
    const courseId = course.id;
    await api.delete(`/enrollments`, {
      data: { students: studentIds, courseId: courseId },
    });
    await refreshUserInfo(false);
    fetchStudents();
  };

  const fetchStudents = () => {
    setLoading(true);
    setError(null);
    if (rosters && Object.keys(rosters).length > 0) {
      const students = rosters[course.id].data;
      setRoster(students.sort((a, b) => a.lastName.localeCompare(b.lastName)));
      setLoading(false);
    } else {
      setLoading(false)
      return;
    }
  };

  useEffect(() => {
    if (!courseLoading) {
      fetchStudents();
    }
  }, [courseLoading]);

  const filteredRoster = roster.filter((student) =>
    `${student.firstName} ${student.lastName} ${student.email}`
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  const renderLink = (student) =>
    `/assignments/status/course/${course.id}/student/${student.uid}`;

  if (loading) {
    return <RosterSkeleton />;
  }

  return (
    <Card className="w-full max-w-3xl mx-auto shadow-xl border-2 border-primary/20 hover:border-primary/30 transition-all duration-300">
      <CardHeader className="bg-primary text-primary-foreground rounded-md">
        <CardTitle className="flex justify-between">
          <div className="text-2xl font-bold flex items-center">
            <Users className="mr-2 h-6 w-6" />
            Course Roster
          </div>
          <Button onClick={() => setIsEditDialogOpen(true)}>Edit</Button>
        </CardTitle>
      </CardHeader>
      <CardContent className="p-4">
        {error ? (
          <Alert variant="destructive">
            <AlertCircle className="h-4 w-4" />
            <AlertTitle>Error</AlertTitle>
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        ) : (
          <RosterTable
            loading={loading}
            filteredRoster={filteredRoster}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            renderLink={renderLink}
          />
        )}
      </CardContent>
      <RosterManager
        isOpen={isEditDialogOpen}
        onClose={() => setIsEditDialogOpen(false)}
        roster={roster}
        handleDelete={handleDeleteStudents}
      />
    </Card>
  );
};

export default CourseRoster;
