import StudentEmailList from "../PaymentPageComponents/StudentEmailList";

import { Check } from "lucide-react";
import { Alert, AlertDescription } from "../../ui/alert.jsx";
import { getSimpleDate } from "../utils/Timestamp";
import { TEACHER_PRO_MAX_STUDENTS } from "../utils/PricesAndLimits";

export const SubscriptionDetails = ({
  planTitle,
  monthlyPayment,
  creationDate,
  subscriptionId,
}) => (
  <div className="space-y-4">
    <h2 className="text-xl font-semibold text-primary">Subscription Details</h2>
    <div className="space-y-2">
      <div className="flex justify-between items-center">
        <span className="text-gray-600">Plan Type:</span>
        <span className="font-medium">{planTitle || "Teacher Pro"}</span>
      </div>
      <div className="flex justify-between items-center">
        <span className="text-gray-600">Monthly Payment:</span>
        <span className="font-medium">${monthlyPayment}</span>
      </div>
      <div className="flex justify-between items-center">
        <span className="text-gray-600">Date Subscribed:</span>
        <span className="font-medium">{getSimpleDate(creationDate)}</span>
      </div>
      <div className="flex justify-between items-center">
        <span className="text-gray-600">Subscription ID: </span>
        <span className="font-medium">{subscriptionId}</span>
      </div>
    </div>
  </div>
);

export const SubscriptionAlert = ({ show }) => {
  if (!show) return null;

  return (
    <Alert className="bg-green-50 border-green-200">
      <Check className="h-4 w-4 text-green-600" />
      <AlertDescription className="text-green-600">
        Subscription updated successfully!
      </AlertDescription>
    </Alert>
  );
};

export const StudentManagement = ({
  newStudentArray,
  setNewStudentArray,
  newMonthlyPayment,
}) => (
  <div className="space-y-4 pt-6">
    <h2 className="text-xl font-semibold text-primary">Edit Students</h2>
    {newStudentArray.length > 0 && (
      <p className="text-sm text-gray-600">
        To remove a student from your plan, hover on the existing address and
        click the "X".
      </p>
    )}
    <StudentEmailList
      students={newStudentArray}
      setStudents={setNewStudentArray}
      disabled={newStudentArray.length >= TEACHER_PRO_MAX_STUDENTS}
    />
    <p className="text-gray-600">
      Estimated Monthly Payment ${newMonthlyPayment}
    </p>
  </div>
);

export const CurrentStudentsList = ({ students }) => (
  <div className="space-y-4">
    <h2 className="text-xl font-semibold text-primary">Current Students</h2>
    <div className="bg-gray-50 p-4 rounded-lg max-h-40 overflow-y-scroll">
      {students && students.length > 0 ? (
        <div className="grid gap-2">
          {students.map((student, index) => (
            <p key={index} className="text-sm text-gray-600">
              {student}
            </p>
          ))}
        </div>
      ) : (
        <p className="text-sm text-gray-500">No students enrolled</p>
      )}
    </div>
  </div>
);
