import { Button } from "../../ui/button.jsx";

const ConfirmButton = ({ handleSubmit, disabled }) => {
  return (
    <Button
      type="submit"
      disabled={disabled}
      className="px-6 py-2"
      onClick={handleSubmit}
    >
      Proceed to Payment
    </Button>
  );
};
export default ConfirmButton;
