import React, { useState } from "react";
import { Trash2, AlertCircle } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "../../../ui/dialog.jsx";
import { Button } from "../../../ui/button.jsx";
import { ScrollArea } from "../../../ui/scroll-area.jsx";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../ui/table.jsx";
import { Alert, AlertDescription } from "../../../ui/alert.jsx";

const RosterManager = ({ isOpen, onClose, roster, handleDelete }) => {
  const [studentsToDelete, setStudentsToDelete] = useState([]);
  const [showConfirmButton, setShowConfirmButton] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [deletionCompleted, setDeletionCompleted] = useState(false);

  const toggleDeleteStudent = (studentId) => {
    setStudentsToDelete((prev) => {
      const newStudentsToDelete = prev.includes(studentId)
        ? prev.filter((id) => id !== studentId)
        : [...prev, studentId];
      setShowConfirmButton(newStudentsToDelete.length > 0);
      return newStudentsToDelete;
    });
  };

  const confirmDelete = async () => {
    setDeleting(true);
    await handleDelete(studentsToDelete);
    setStudentsToDelete([]);
    setShowConfirmButton(false);
    setDeleting(false);
    setDeletionCompleted(true);
    onClose();
  };

  return (
    <>
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>Edit Roster</DialogTitle>
          </DialogHeader>
          <ScrollArea className="h-[300px] w-full pr-4">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Name</TableHead>
                  <TableHead>Action</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {roster.map((student) => (
                  <TableRow key={student.uid}>
                    <TableCell>{`${student.lastName}, ${student.firstName}`}</TableCell>
                    <TableCell>
                      <Button
                        variant={
                          studentsToDelete.includes(student.uid)
                            ? "destructive"
                            : "ghost"
                        }
                        size="icon"
                        onClick={() => toggleDeleteStudent(student.uid)}
                      >
                        <Trash2 className="h-4 w-4" />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </ScrollArea>
          {studentsToDelete.length > 0 && (
            <Alert variant="warning">
              <AlertCircle className="h-4 w-4" />
              <AlertDescription>
                {studentsToDelete.length} student
                {studentsToDelete.length > 1 ? "s" : ""} marked for removal.
                This action cannot be undone after confirming.
              </AlertDescription>
            </Alert>
          )}
          <DialogFooter>
            <Button variant="outline" onClick={onClose}>
              Cancel
            </Button>
            {showConfirmButton && (
              <Button
                variant="destructive"
                disabled={deleting}
                onClick={confirmDelete}
              >
                {deleting ? "Removing students..." : "Confirm Removals"}
              </Button>
            )}
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <Dialog
        open={deletionCompleted}
        onOpenChange={() => setDeletionCompleted(false)}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Students Successfully Removed</DialogTitle>
          </DialogHeader>
          <p>Your changes have taken effect. You can close this dialog.</p>
          <DialogFooter>
            <Button onClick={() => setDeletionCompleted(false)}>Close</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default RosterManager;
