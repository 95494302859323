import { useUser } from "../../Contexts/userContext";
import { useNavigate } from "react-router-dom";
import { CircleUserRound } from "lucide-react";
import { Button } from "../../components/ui/button.jsx";
import BaseLayout from "../../components/layout/BaseLayout";
import { Card, CardContent, CardHeader } from "../../components/ui/card.jsx";
import api from "../../api";
import { useState, useEffect } from "react";
import { getSimpleDate } from "../../components/shared/utils/Timestamp";
import { TEACHER_PRO_MAX_STUDENTS } from "../../components/shared/utils/PricesAndLimits";

const ProfileField = ({ label, value }) => (
  <p className="text-gray-600 text-lg">
    <b>{label}: </b>
    {value}
  </p>
);

const AccountDetails = ({ user }) => (
  <Card className="shadow-lg p-8 mr-4">
    <CardHeader>
      <h2 className="text-2xl text-primary font-bold">Account Details</h2>
    </CardHeader>
    <div className="grid grid-cols-1 items-center gap-4">
      <CardContent>
        <div className="flex flex-col gap-2">
          <ProfileField label="First Name" value={user.firstName} />
          <ProfileField label="Last Name" value={user.lastName} />
          <ProfileField label="Email" value={user.email} />
          <ProfileField
            label="Account Type"
            value={user.role === "teacher" ? "Teacher" : "Student"}
          />
        </div>
      </CardContent>
    </div>
  </Card>
);

const ErrorCard = () => (
  <Card className="shadow-lg p-8">
    <CardContent className="flex-1">
      <div className="flex items-center justify-center text-red-600">
        Sorry, there was an error fetching your data. Please try again later or
        contact{" "}
        <a
          href="mailto:jake@AilaEducation.com"
          className="text-primary hover:underline"
        >
          jake@AilaEducation.com
        </a>{" "}
      </div>
    </CardContent>
  </Card>
);

const PlanDetails = ({ user, subscriptionData }) => {
  const navigate = useNavigate();

  const handleEditSub = () => {
    navigate(`/subscription/${user.uid}/edit`, {
      state: {
        ...subscriptionData,
      },
    });
  };

  if (subscriptionData === null && user.paid) {
    return <ErrorCard />;
  }

  const students = subscriptionData.students;

  return (
    <Card className="shadow-lg p-8">
      <div className="flex flex-col gap-2 h-full">
        <CardHeader className="text-primary font-bold text-2xl">
          Plan Details
        </CardHeader>
        <CardContent className="flex-1">
          {user.paid ? (
            <div className="flex flex-col h-full">
              <div className="flex text-lg gap-2">
                <h1 className="font-bold text-gray-600 mr-2">Plan Type: </h1>
                <p>{subscriptionData.planTitle || "Teacher Pro"}</p>
              </div>
              <div className="flex text-lg">
                <h1 className="font-bold text-gray-600 mr-2">
                  Date Subscribed:{" "}
                </h1>
                <p>{getSimpleDate(subscriptionData.creationDate)}</p>
              </div>
              {students ? (
                <div className="flex text-lg">
                  <h1 className="font-bold text-gray-600 mr-2">Students:</h1>
                  <p>
                    {students.length} of {TEACHER_PRO_MAX_STUDENTS} limit
                  </p>
                </div>
              ) : (
                <h1 className="font-bold text-gray-600 mr-2">Students: N/A</h1>
              )}
            </div>
          ) : (
            <div className="flex flex-col h-full">
              <div className="flex text-lg">
                <h1 className="font-bold text-gray-600 mr-2">Plan Type: </h1>
                <h2>Free Plan</h2>
              </div>
            </div>
          )}
        </CardContent>
        <div className="flex mt-auto pt-0 justify-left">
          {user.paid ? (
            <Button
              variant="outline"
              onClick={handleEditSub}
              className="w-full md:w-auto"
            >
              View and Manage Subscription
            </Button>
          ) : (
            <UpgradeButton />
          )}
        </div>
      </div>
    </Card>
  );
};

const UpgradeButton = () => {
  const navigate = useNavigate();
  const handleUpgrade = () => {
    navigate("/plans");
  };
  return (
    <Button className="mx-8 text-center" onClick={handleUpgrade}>
      Click Here to Upgrade
    </Button>
  );
};

const LoadingSpinner = () => (
  <div className="flex justify-center items-center h-screen">
    <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-primary" />
  </div>
);

const ErrorMessage = ({ error }) => (
  <div className="flex justify-center items-center h-screen">
    <div className="text-red-500 text-xl">
      Error loading profile: {error.message}
    </div>
  </div>
);

const ProfilePage = () => {
  const { user, isLoading, error } = useUser();
  const [fetchingData, setFetchingData] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      if (user?.paid) {
        try {
          setFetchingData(true);
          const userResponse = await api.get(`/users/${user.uid}`);
          setSubscriptionData(userResponse.data.subscription);
        } catch (error) {
          setSubscriptionData(null);
        } finally {
          setFetchingData(false);
        }
      }
    };

    fetchData();
  }, [user]);

  if (isLoading || fetchingData) return <LoadingSpinner />;
  if (error) return <ErrorMessage error={error} />;
  if (!user) {
    navigate("/login");
    return null;
  }

  return (
    <BaseLayout>
      <div className="flex gap-2 justify-center items-center">
        <CircleUserRound className="text-primary/75 h-16 w-16" />
        <h1 className="text-4xl text-primary/75 font-bold">Your Profile</h1>
      </div>
      <div className="w-full grid grid-cols-1 md:grid-cols-2 justify-between p-8">
        <AccountDetails user={user} />
        {user.role !== "student" && (
          <PlanDetails user={user} subscriptionData={subscriptionData} />
        )}
      </div>
    </BaseLayout>
  );
};

export default ProfilePage;
