import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import ConditionalActivityTracker from "./ActivityTrackers/ConditionalActivityTracker";
import { LoadingProvider } from "./Contexts/loadingContext";
import { UserProvider } from "./Contexts/userContext";
import ProtectedRoute from "./ProtectedRoute";
import AssignmentCreator from "./components/teacher/AssignmentCreation/AssignmentCreator";
import AssignmentInterfacePage from "./pages/AssignmentInterfacePage";
import StudentCourseView from "./pages/CourseView/StudentCourseView";
import TeacherCourseView from "./pages/CourseView/TeacherCourseView";
import Homepage from "./pages/Homepage/Homepage";
import CreateAccountPage from "./pages/Landing/CreateAccountPage";
import LoginPage from "./pages/Landing/LoginPage";
import UnauthorizedPage from "./pages/Security/UnauthorizedPage";
import AllSubsPage from "./pages/SubmissionLists/AllSubsPage";
import StudentSubsPage from "./pages/SubmissionLists/StudentSubsPage";
import StudentReviewPage from "./pages/SubmissionReview/Student/StudentReviewPage";
import CourseSubmissionsReviewPage from "./pages/SubmissionReview/Teacher/CourseSubmissionsReviewPage";
import StudentSubmissionReviewPage from "./pages/SubmissionReview/Teacher/StudentSubmissionsReviewPage";
// import AboutPage from "./pages/AboutPage";
import PlansPage from "./pages/PlansPage";
import CheckoutPage from "./pages/PaymentPages/CheckoutPage";
import PaymentSuccessPage from "./pages/Security/PaymentSuccessPage";
import ProfilePage from "./pages/Profile/ProfilePage";
import SubscriptionManagementPage from "./pages/PaymentPages/SubscriptionManagementPage";

const App = () => {
  return (
    <Router>
      <UserProvider>
        <LoadingProvider>
          <ConditionalActivityTracker>
            <Routes>
              <Route path="/" element={<LoginPage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/create-account" element={<CreateAccountPage />} />
              <Route path="/unauthorized" element={<UnauthorizedPage />} />
              <Route path="/homepage" element={<Homepage />} />

              <Route
                path="/course/:courseId/teacher"
                element={
                  <ProtectedRoute
                    allowedRoles={["teacher"]}
                    accessRule={(user, params) => {
                      return user.courses.some(
                        (course) => course.id === params.courseId
                      );
                    }}
                  >
                    <TeacherCourseView />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/course/:courseId/student"
                element={
                  <ProtectedRoute
                    allowedRoles={["student"]}
                    accessRule={(user, params) => {
                      return user.courses.some(
                        (course) => course.id === params.courseId
                      );
                    }}
                  >
                    <StudentCourseView />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/assignment/:assignmentId"
                element={
                  <ProtectedRoute
                    allowedRoles={["student"]}
                    accessRule={(user, params) => {
                      return (
                        user.assignments.some(
                          (assignment) => assignment.id === params.assignmentId
                        ) &&
                        !user.submissions.some(
                          (submission) =>
                            submission.assignmentId === params.assignmentId
                        )
                      );
                    }}
                  >
                    <AssignmentInterfacePage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/assignment/:assignmentId/course/:courseId/edit"
                element={
                  <ProtectedRoute
                    allowedRoles={["teacher"]}
                    accessRule={(user, params) => {
                      return (
                        user.assignments.some(
                          (assignment) => assignment.id === params.assignmentId
                        ) &&
                        user.courses.some(
                          (course) => course.id === params.courseId
                        )
                      );
                    }}
                  >
                    <AssignmentCreator />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/assignment/submissions/:courseId/:assignmentId"
                element={
                  <ProtectedRoute
                    allowedRoles={["teacher"]}
                    accessRule={(user, params) => {
                      return user.courses.some(
                        (course) => course.id === params.courseId
                      );
                    }}
                  >
                    <AllSubsPage />
                  </ProtectedRoute>
                }
              />
              {/*Note for later: Need to ensure teacher has userId as student. Low-risk, but still. */}
              <Route
                path="/assignment/submissions/review/:assignmentId"
                element={
                  <ProtectedRoute
                    allowedRoles={["student"]}
                    accessRule={(user, params) => {
                      return user.assignments.some(
                        (assignment) => assignment.id === params.assignmentId
                      );
                    }}
                  >
                    <StudentReviewPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/assignment/:assignmentId/user/:userId/course/:courseId/submissions"
                element={
                  <ProtectedRoute
                    allowedRoles={["teacher"]}
                    accessRule={(user, params) => {
                      return (
                        user.courses.some(
                          (course) => course.id === params.courseId
                        ) &&
                        user.assignments.some(
                          (assignment) => assignment.id === params.assignmentId
                        )
                      );
                    }}
                  >
                    <CourseSubmissionsReviewPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/course/:courseId/user/:userId/submissions/:assignmentId"
                element={
                  <ProtectedRoute
                    allowedRoles={["teacher"]}
                    accessRule={(user, params) => {
                      return user.courses.some(
                        (course) => course.id === params.courseId
                      );
                    }}
                  >
                    <StudentSubmissionReviewPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/assignments/status/course/:courseId/student/:studentId"
                element={
                  <ProtectedRoute
                    allowedRoles={["teacher"]}
                    accessRule={(user, params) => {
                      return user.courses.some(
                        (course) => course.id === params.courseId
                      );
                    }}
                  >
                    <StudentSubsPage />
                  </ProtectedRoute>
                }
              />
              {/* <Route
                path="/about"
                element={
                  <ProtectedRoute
                    allowedRoles={["teacher"]}
                    accessRule={() => {
                      return true;
                    }}
                  >
                    <AboutPage />
                  </ProtectedRoute>
                }
              /> */}
              <Route
                path="/plans"
                element={
                  <ProtectedRoute
                    allowedRoles={["teacher"]}
                    accessRule={(user) => {
                      return user.uid !== null;
                    }}
                  >
                    <PlansPage />
                  </ProtectedRoute>
                }
              />

              <Route
                path="*"
                element={<Navigate to="/unauthorized" replace />}
              />
              <Route
                path="/plans/purchase/:plan"
                element={
                  <ProtectedRoute
                    allowedRoles={"teacher"}
                    accessRule={(user) => {
                      return !user.paid;
                    }}
                  >
                    <CheckoutPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/payment/success"
                element={<PaymentSuccessPage />}
              ></Route>
              <Route
                path="/profile/:userId"
                element={
                  <ProtectedRoute
                    allowedRoles={["teacher", "student"]}
                    accessRule={(user, params) => {
                      return user.uid === params.userId;
                    }}
                  >
                    <ProfilePage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="subscription/:userId/edit"
                element={
                  <ProtectedRoute
                    allowedRoles={["teacher"]}
                    accessRule={(user, params) => {
                      return user.uid === params.userId && user.paid;
                    }}
                  >
                    <SubscriptionManagementPage />
                  </ProtectedRoute>
                }
              ></Route>
            </Routes>
          </ConditionalActivityTracker>
        </LoadingProvider>
      </UserProvider>
    </Router>
  );
};

export default App;
