import { AlertCircle, FileText, FileWarning } from "lucide-react";
import { Alert, AlertDescription, AlertTitle } from "../../ui/alert";
import { Card, CardContent, CardHeader, CardTitle } from "../../ui/card";
import { ScrollArea } from "../../ui/scroll-area";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../ui/tabs.jsx";
import { renderAssignmentList, renderSkeletons } from "./Helper";

const SharedAssignmentList = ({
  title,
  isLoading,
  error,
  inProgressAssignments,
  unstartedAssignments,
  icon,
}) => {
  const renderContent = () => {
    if (error) {
      return (
        <Alert variant="destructive">
          <AlertCircle className="h-4 w-4" />
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      );
    }

    return (
      <Tabs
        defaultValue={
          unstartedAssignments.length > 0 ? "unopened" : "in-progress"
        }
        className="w-full"
      >
        <TabsList className="grid w-full grid-cols-2 mb-6">
          <TabsTrigger
            value="in-progress"
            className="data-[state=active]:bg-primary data-[state=active]:text-primary-foreground font-semibold"
          >
            In Progress ({isLoading ? "..." : inProgressAssignments.length})
          </TabsTrigger>
          <TabsTrigger
            value="unopened"
            className="data-[state=active]:bg-primary data-[state=active]:text-primary-foreground font-semibold"
          >
            Unopened ({isLoading ? "..." : unstartedAssignments.length})
          </TabsTrigger>
        </TabsList>
        <ScrollArea className="h-[400px] w-full pr-4 rounded-md border border-primary/10">
          <TabsContent value="in-progress" className="p-4">
            {isLoading
              ? renderSkeletons(5)
              : renderAssignmentList(
                  inProgressAssignments,
                  <FileText className="h-5 w-5 text-primary" />
                )}
          </TabsContent>
          <TabsContent value="unopened" className="p-4">
            {isLoading
              ? renderSkeletons(5)
              : renderAssignmentList(
                  unstartedAssignments,
                  <FileWarning className="h-5 w-5 text-primary" />
                )}
          </TabsContent>
        </ScrollArea>
      </Tabs>
    );
  };

  return (
    <Card className="w-full max-w-3xl mx-auto shadow-xl border-2 border-primary/20 hover:border-primary/30 transition-all duration-300">
      <CardHeader className="bg-primary text-primary-foreground rounded-md">
        <CardTitle className="text-2xl font-bold flex items-center">
          {icon} {title}
        </CardTitle>
      </CardHeader>
      <CardContent className="p-6">{renderContent()}</CardContent>
    </Card>
  );
};

export default SharedAssignmentList;
