import { PlusCircle, X, Info } from "lucide-react";
import { useEffect, useState } from "react";
import { Button } from "../../../ui/button.jsx";
import { Card, CardContent } from "../../../ui/card.jsx";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../../../ui/carousel.jsx";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  TooltipProvider,
} from "../../../ui/tooltip.jsx";
import Question from "./Question";
import SearchBar from "./SearchBar";
import { FREE_QUESTION_LIMIT } from "../../../shared/utils/PricesAndLimits";

const QuestionList = ({
  questions,
  setQuestions,
  assignmentBotPermissions,
  limit,
}) => {
  const [api, setApi] = useState(null);
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    setQuestions(
      questions.map((question) => ({
        ...question,
        chatbotPermitted: assignmentBotPermissions,
      }))
    );
  }, [assignmentBotPermissions]);

  useEffect(() => {
    if (api) {
      const updateCurrent = () => {
        setCurrent(api.selectedScrollSnap());
      };

      updateCurrent();
      const intervalId = setInterval(updateCurrent, 100);
      return () => clearInterval(intervalId);
    }
  }, [api]);

  const onAddQuestion = () => {
    setQuestions([
      ...questions,
      {
        text: "",
        instruction: "",
        points: "",
        type: "",
        choices: [],
        correctChoices: [],
        chatbotPermitted: assignmentBotPermissions,
      },
    ]);
    setTimeout(() => {
      if (api) {
        api.scrollTo(questions.length);
      }
    }, 0);
  };

  const onDeleteQuestion = (indexToDelete) => {
    const updatedQuestions = questions.filter(
      (_, index) => index !== indexToDelete
    );
    setQuestions(updatedQuestions);
    if (api && current >= updatedQuestions.length) {
      api.scrollTo(Math.max(0, updatedQuestions.length - 1));
    }
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.target.tagName === "INPUT" || e.target.tagName === "TEXTAREA") {
        e.stopPropagation();
      }
    };

    document.addEventListener("keydown", handleKeyDown, true);

    return () => {
      document.removeEventListener("keydown", handleKeyDown, true);
    };
  }, []);

  const remainingQuestions = limit ? Math.max(FREE_QUESTION_LIMIT - questions.length, 0) : "∞";

  return (
    <div className="w-full max-w-4xl mx-auto">
      <SearchBar questions={questions} api={api} />
      <Carousel setApi={setApi} className="w-full">
        <CarouselContent>
          {questions.map((question, index) => (
            <CarouselItem key={index}>
              <Card className="p-4 relative">
                <Button
                  onClick={() => onDeleteQuestion(index)}
                  className="absolute top-2 right-2 p-1 h-8 w-8"
                  variant="destructive"
                >
                  <X className="h-4 w-4" />
                </Button>
                <CardContent>
                  <Question
                    question={question}
                    setQuestions={setQuestions}
                    questions={questions}
                    index={index}
                    assignmentBotPermissions={assignmentBotPermissions}
                  />
                </CardContent>
              </Card>
            </CarouselItem>
          ))}
        </CarouselContent>
        <CarouselPrevious />
        <CarouselNext />
      </Carousel>
      <div className="flex justify-between items-center mt-4">
        <div className="flex items-center gap-2">
          <Button
            onClick={onAddQuestion}
            className="flex items-center"
            disabled={limit && questions.length >= FREE_QUESTION_LIMIT}
          >
            <PlusCircle className="mr-2 h-4 w-4" /> Add Question
          </Button>
          {limit && (
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <div className="flex items-center text-sm text-gray-500">
                    <Info className="h-4 w-4 mr-1" />
                    {remainingQuestions} remaining
                  </div>
                </TooltipTrigger>
                <TooltipContent>
                  <p>
                    Free accounts can create up to {FREE_QUESTION_LIMIT} questions per assignment.
                  </p>
                  <p className="text-xs mt-1">
                    Need more? Consider upgrading your account.
                  </p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          )}
        </div>
        <p className="text-sm text-gray-500">
          {questions.length > 0 &&
            `Question ${current + 1} of ${questions.length}`}
        </p>
      </div>
    </div>
  );
};

export default QuestionList;
