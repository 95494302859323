import { AlertCircle, Users } from "lucide-react";
import { useEffect, useState } from "react";
import api from "../../../../api";
import { useUser } from "../../../../Contexts/userContext";
import { Alert, AlertDescription, AlertTitle } from "../../../ui/alert.jsx";
import { Card, CardContent, CardHeader, CardTitle } from "../../../ui/card.jsx";
import { createStudentCourseMap } from "./Helper.js";
import RosterSkeleton from "./RosterSkeleton";
import RosterTable from "./RosterTable";
import useUserInfo from "../../../../Contexts/useUserInfo";

const MasterRoster = ({ courses, coursesLoading }) => {
  const [loading, setLoading] = useState(true);
  const [roster, setRoster] = useState([]);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [studentCourseMap, setStudentCourseMap] = useState({});
  const { user } = useUser();
  const userId = user.uid;
  const { rosters } = useUserInfo();

  const fetchStudents = async () => {
    setLoading(true);
    setError(null);

    if (rosters && Object.keys(rosters).length > 0) {
      const allStudents = Object.keys(rosters).reduce(
        (acc, courseKey) => {
          const courseStudents = rosters[courseKey].data;

          acc.students.push(...courseStudents);

          courseStudents.forEach((student) => {
            acc.courseMap[student.uid] = courseKey;
          });

          return acc;
        },
        { students: [], courseMap: {} }
      );

      setStudentCourseMap(allStudents.courseMap);

      setRoster(
        allStudents.students.sort((a, b) =>
          a.lastName.localeCompare(b.lastName)
        )
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!coursesLoading) {
      fetchStudents();
    }
  }, [userId, coursesLoading]);

  const filteredRoster = roster.filter((student) =>
    `${student.firstName} ${student.lastName} ${student.email}`
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  const renderLink = (student) => {
    return `/assignments/status/course/${
      studentCourseMap[student.uid]
    }/student/${student.uid}`;
  };

  if (loading || coursesLoading) {
    return <RosterSkeleton master={true} />;
  }

  return (
    <Card className="w-full max-w-3xl mx-auto shadow-xl border-2 border-primary/20 hover:border-primary/30 transition-all duration-300">
      <CardHeader className="bg-primary text-primary-foreground rounded-md">
        <CardTitle className="text-2xl font-bold flex items-center">
          <Users className="mr-2 h-6 w-6" />
          Master Roster
        </CardTitle>
      </CardHeader>
      <CardContent className="p-4">
        {error ? (
          <Alert variant="destructive">
            <AlertCircle className="h-4 w-4" />
            <AlertTitle>Error</AlertTitle>
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        ) : (
          <RosterTable
            loading={loading}
            filteredRoster={filteredRoster}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            renderLink={renderLink}
          />
        )}
      </CardContent>
    </Card>
  );
};

export default MasterRoster;
