import React from "react";
import { Card, CardFooter } from "../../ui/card.jsx";
import { School, Phone } from "lucide-react";
import { STUDENT_YEARLY_COST } from "../utils/PricesAndLimits";

const EnterpriseCard = ({ highlight = false }) => {
  return (
    <Card
      className={`flex flex-col h-full relative ${
        highlight ? "border-2 border-primary shadow-lg shadow-primary/20" : ""
      }`}
    >
      {highlight && (
        <div className="bg-primary text-center text-white transform -translate-y-4 px-4 py-1 -mb-7 rounded-full text-sm font-medium">
          Your Current Plan
        </div>
      )}
      {/* Header */}
      <div className="p-8">
        <div className="space-y-4">
          <div className="flex items-center gap-2">
            <School className="h-6 w-6 text-blue-600" />
            <h2 className="text-2xl font-medium text-gray-900">
              Enterprise Pro
            </h2>
          </div>

          {/* Pricing */}
          <div>
            <div className="flex items-baseline">
              <span className="text-3xl font-bold text-primary">
                Contact Sales
              </span>
            </div>
            <p className="text-sm text-gray-500 mt-2">
              Starting at ${STUDENT_YEARLY_COST}/student/year
            </p>
            <p className="text-sm font-medium text-blue-600 mt-1">
              Volume discounts available
            </p>
          </div>

          {/* Features List */}
          <div className="mt-8">
            <div className="space-y-6">
              {/* Includes Pro Features */}
              <div className="bg-gray-50 p-4 rounded-lg">
                <p className="font-medium text-gray-900 mb-2">
                  Includes all Classroom Pro features plus:
                </p>
              </div>

              {/* Enterprise Features */}
              <div className="space-y-3">
                <h3 className="text-lg font-medium text-gray-900 mb-4">
                  Plan Features:
                </h3>
                <ul className="space-y-2">
                  <li className="flex items-center">
                    <span className="text-gray-700">
                      Dedicated account manager
                    </span>
                  </li>
                  <li className="flex items-center">
                    <span className="text-gray-700">
                      Advanced administrative dashboard
                    </span>
                  </li>
                  <li className="flex items-center">
                    <span className="text-gray-700">School-wide analytics</span>
                  </li>
                  <li className="flex items-center">
                    <span className="text-gray-700">SLA guarantees</span>
                  </li>
                  <li className="flex items-center">
                    <span className="text-gray-700">
                      Multi-year discount options
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-auto p-8 pt-0">
        <button className="w-full bg-primary text-white py-3 px-4 rounded-lg hover:bg-blue-700 transition-colors flex items-center justify-center gap-2">
          Contact Sales
        </button>
      </div>
    </Card>
  );
};

export default EnterpriseCard;
