import { CircleUserRound } from "lucide-react";
import { useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useUser } from "../../Contexts/userContext";
import LogoutButton from "../auth/LogoutButton";
import { Button } from "../ui/button.jsx";

const BaseLayout = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showCourseDropdown, setShowCourseDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const timeoutRef = useRef(null);
  const { user, courses, loading, error } = useUser();

  const handleCourseMouseEnter = () => {
    clearTimeout(timeoutRef.current);
    setShowCourseDropdown(true);
  };

  const handleCourseMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setShowCourseDropdown(false);
    }, 300);
  };

  const handleCourseSelect = (courseId) => {
    navigate(`/course/${courseId}/${user.role}`);
    setShowCourseDropdown(false);
  };

  const handleProfileClick = () => {
    navigate(`/profile/${user.uid}`);
  };

  const isActiveLink = (path) => {
    if (path === "/homepage") {
      return location.pathname === "/homepage";
    }
    if (path === "courses") {
      return location.pathname.startsWith("/course/");
    }
    if (path === "plans") {
      return location.pathname.startsWith("/plans");
    }
    return location.pathname === path;
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
      <header className="bg-white shadow-sm">
        <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex">
              <div className="flex-shrink-0 flex items-center">
                <img
                  src="/HeaderLogo.png"
                  alt="Header Logo"
                  className="h-8 w-auto"
                />
              </div>
              <div className="hidden sm:ml-6 sm:flex sm:space-x-6">
                <a
                  href="/homepage"
                  className={`${
                    isActiveLink("/homepage")
                      ? "border-primary text-gray-900"
                      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                  } inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium`}
                >
                  Dashboard
                </a>
                <div
                  className="relative inline-flex items-center"
                  ref={dropdownRef}
                  onMouseEnter={handleCourseMouseEnter}
                  onMouseLeave={handleCourseMouseLeave}
                >
                  <button
                    className={`${
                      isActiveLink("courses")
                        ? "border-primary text-gray-900"
                        : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                    } inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium h-full`}
                  >
                    Courses
                  </button>
                  {showCourseDropdown && (
                    <div className="absolute top-full left-0 z-10 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                      <div
                        className="py-1"
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="options-menu"
                      >
                        {loading ? (
                          <div className="px-4 py-2 text-sm text-gray-700">
                            Loading...
                          </div>
                        ) : error ? (
                          <div className="px-4 py-2 text-sm text-gray-700">
                            Error loading courses
                          </div>
                        ) : courses.length > 0 ? (
                          courses.map((course) => (
                            <a
                              key={course.id}
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                              role="menuitem"
                              href={`/course/${course.id}/${user.role}`}
                              onClick={(e) => {
                                e.preventDefault();
                                handleCourseSelect(course.id);
                              }}
                            >
                              {course.title}
                            </a>
                          ))
                        ) : (
                          <div className="px-4 py-2 text-sm text-gray-700">
                            No courses
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex mx-4">
                {user.role === "teacher" && !user.paid && (
                  <a
                    href="/plans"
                    className={`${
                      isActiveLink("plans")
                        ? "border-primary text-gray-900"
                        : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                    } inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium`}
                  >
                    Upgrade
                  </a>
                )}
              </div>
              {/* <div className="flex">
                {user.role === "teacher" && (
                  <a
                    href="/about"
                    className={`${
                      isActiveLink("/about")
                        ? "border-primary text-gray-900"
                        : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                    } inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium`}
                  >
                    About
                  </a>
                )}
              </div> */}
            </div>
            <div className="flex items-center space-x-4">
              <span className="text-sm font-medium text-gray-700 flex items-center">
                Welcome, {user.firstName}{" "}
                <Button
                  onClick={handleProfileClick}
                  className="ml-2 bg-transparent hover:bg-primary/15 transition-colors text-primary"
                >
                  <CircleUserRound className="h-6 w-6" />
                </Button>
              </span>
              <LogoutButton />
            </div>
          </div>
        </nav>
      </header>

      <main className="flex-grow">
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">{children}</div>
      </main>

      <footer className="bg-white">
        <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
          <p className="text-center text-sm text-gray-500">
            Copyright © 2025 Aila Education, LLC
          </p>
        </div>
      </footer>
    </div>
  );
};

export default BaseLayout;
