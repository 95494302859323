import React from "react";

const FormSkeleton = () => {
  return (
    <div className="w-full max-w-lg mx-auto p-6 space-y-6 bg-white rounded-lg shadow-sm animate-pulse">
      {/* Card number field skeleton */}
      <div className="space-y-2">
        <div className="h-4 w-24 bg-gray-200 rounded" />
        <div className="h-10 bg-gray-200 rounded" />
      </div>

      {/* Expiry and CVC fields skeleton */}
      <div className="grid grid-cols-2 gap-4">
        <div className="space-y-2">
          <div className="h-4 w-20 bg-gray-200 rounded" />
          <div className="h-10 bg-gray-200 rounded" />
        </div>
        <div className="space-y-2">
          <div className="h-4 w-16 bg-gray-200 rounded" />
          <div className="h-10 bg-gray-200 rounded" />
        </div>
      </div>

      {/* Order summary skeleton */}
      <div className="space-y-3 mt-8 pt-6 border-t">
        <div className="flex justify-between items-center">
          <div className="h-4 w-24 bg-gray-200 rounded" />
          <div className="h-4 w-16 bg-gray-200 rounded" />
        </div>
        <div className="flex justify-between items-center">
          <div className="h-4 w-32 bg-gray-200 rounded" />
          <div className="h-4 w-20 bg-gray-200 rounded" />
        </div>
      </div>

      {/* Pay button skeleton */}
      <div className="h-12 bg-gray-200 rounded-md mt-6" />
    </div>
  );
};

export default FormSkeleton;
