import { Button } from "../../ui/button.jsx";
import { useState } from "react";

const CancelButton = () => {
  const [confirm, setConfirm] = useState(false);
  const handleClick = () => {
    if (!confirm) {
      setConfirm(true);
    } else {
      window.location.reload();
    }
  };

  return (
    <Button variant={confirm ? "destructive" : "outline"} onClick={handleClick}>
      {confirm ? "Confirm Cancellation?" : "Cancel Payment"}
    </Button>
  );
};

export default CancelButton;
