import { useEffect, useRef, useState } from "react";
import api from "../../../api";
import { useUser } from "../../../Contexts/userContext";
import { useThrowToastError } from "../../shared/utils/utils";
import useChatHistory from "./useChatHistory";
import ChatInput from "./ChatInput";
import ChatMessage from "./ChatMessage";
import { getInstructions } from "./InstructionFormatter.js";
import TypingIndicator from "./TypingIndicator";
import { FREE_CHAT_LIMIT } from "../../shared/utils/PricesAndLimits";

const ChatComponent = ({ chatLogId, question, exiting }) => {
  const [message, setMessage] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [chatCount, setChatCount] = useState(0);
  const [chatDisabled, setChatDisabled] = useState(false);
  const chatContainerRef = useRef(null);
  const throwToastError = useThrowToastError();
  const { user } = useUser();
  const paid = user.paid;
  const studentFirstName = user.firstName;
  const [chatsRemaining, setChatsRemaining] = useState(FREE_CHAT_LIMIT);

  const { chatLogs, updateChatLog, loading, saveLogs } =
    useChatHistory(chatLogId);

  const saveChatHistory = async () => {
    try {
      await saveLogs();
    } catch (error) {
      throwToastError("Error logging chat history");
    }
  };

  useEffect(() => {
    if (exiting) {
      saveChatHistory();
    }
  }, [exiting]);

  useEffect(() => {
    if (chatCount >= 2) {
      //change this if we don't want to save every chat, but because Redis does, I think it is probably smart
      saveChatHistory();
      setChatCount(0);
    }
  }, [chatCount]);

  useEffect(() => {
    if (chatLogs[question.id]?.length > 0) {
      const usedChats = Math.ceil(chatLogs[question.id].length / 2); // Each exchange is 2 messages
      const remaining = Math.max(0, FREE_CHAT_LIMIT - usedChats);
      setChatsRemaining(remaining);
    } else {
      setChatsRemaining(FREE_CHAT_LIMIT);
    }
  }, [chatLogs, question.id]);

  useEffect(() => {
    const hasExceededLimit = chatsRemaining <= 0;
    setChatDisabled(hasExceededLimit && !paid);
  }, [chatLogs, paid, chatsRemaining]);

  const handleChat = async () => {
    try {
      updateChatLog(question.id, "user", message);
      setMessage("");
      setIsTyping(true);

      const instructions = getInstructions(studentFirstName, 1, question);

      const response = await api.post("/chat", {
        chatLogId: chatLogId,
        questionId: question.id,
        message,
        instructions,
      });

      const reply = response.data.reply;
      setIsTyping(false);
      updateChatLog(question.id, "bot", reply);
      setChatCount(chatCount + 2);
      scrollToBottom();
    } catch (error) {
      setIsTyping(false);
      throwToastError("Chat is not responding");
    }
  };

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatLogs, question, isTyping]);

  if (!question.chatbotPermitted) {
    return (
      <div className="h-full flex items-center justify-center">
        <p className="text-center text-sm text-gray-900">
          Your instructor has disabled chat permissions for this question.
        </p>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="h-full flex items-center justify-center">
        <p className="text-gray-500">Loading...</p>
      </div>
    );
  }

  return (
    <div className="h-full flex flex-col">
      <div
        ref={chatContainerRef}
        className="h-[85%] overflow-y-auto p-4 space-y-4 bg-gray-50"
      >
        {chatLogs[question.id]?.map((chat, index) => (
          <ChatMessage
            key={index}
            type={chat.type}
            content={chat.chat}
            timestamp={chat.timestamp}
          />
        ))}
        {isTyping && <TypingIndicator />}
      </div>
      <div className="h-[15%] border-t">
        <ChatInput
          message={message}
          setMessage={setMessage}
          handleChat={handleChat}
          chatDisabled={chatDisabled}
        />
      </div>
      {chatDisabled && (
        <p className="text-red-500 text-center m-2">
          Question chat limit reached ({FREE_CHAT_LIMIT} chats). Please see your
          instructor to upgrade.{" "}
        </p>
      )}
      {!paid && !chatDisabled && (
        <p className="text-gray-600 text-center m-2">
          Chats remaining: {chatsRemaining}
        </p>
      )}
    </div>
  );
};

export default ChatComponent;
