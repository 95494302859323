import { useUser } from "../../Contexts/userContext";
import { useState, useEffect } from "react";
import BaseLayout from "../../components/layout/BaseLayout";
import { Label } from "../../components/ui/label.jsx";
import { Input } from "../../components/ui/input.jsx";
import { Card, CardHeader, CardContent } from "../../components/ui/card.jsx";
import StudentEmailList from "../../components/shared/PaymentPageComponents/StudentEmailList";
import {
  TEACHER_PRO_PRICE,
  TEACHER_PRO_VOLUME_PRICING,
  TEACHER_PRO_MAX_STUDENTS,
  TEACHER_PRO_PRODUCT_ID,
} from "../../components/shared/utils/PricesAndLimits";
import FormComponents from "../../components/shared/PaymentPageComponents/FormComponents";
import SubscriptionHeader from "../../components/shared/PaymentPageComponents/SubscriptionHeader";
import ConfirmButton from "../../components/shared/PaymentPageComponents/ConfirmButton";
import { useNavigate } from "react-router-dom";
import { ChevronLeft } from "lucide-react";
import { Button } from "../../components/ui/button.jsx";
import PaymentForm from "../../components/shared/Stripe/PaymentForm";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import api from "../../api";
import FormSkeleton from "../../components/shared/Stripe/FormSkeleton";
import {
  getTeacherProTotal,
  getMonthlyTotal,
} from "../../components/shared/utils/PricesAndLimits";

const TeacherProCheckout = () => {
  const { user } = useUser();
  const [addStudents, setAddStudents] = useState(null);
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [email, setEmail] = useState(user.email);
  const [students, setStudents] = useState([]);
  const [clientSecret, setClientSecret] = useState("");
  const navigate = useNavigate();
  const [infoConfirmed, setInfoConfirmed] = useState(false);
  const [annualTotal, setAnnualTotal] = useState(TEACHER_PRO_PRICE);
  const stripePromise = loadStripe(
    "pk_live_51QeOEwFE09Dv1gzAlHacDK9Uo99ZB3NMP8ryyFcqBRbmulIXWDCOkiHWNTuBKzd2rMXcVdenkvha7KJOJvHBcBfL00mF0GMqxH"
  );
  const [subscriptionId, setSubscriptionId] = useState(null);

  const handleExit = () => {
    navigate("/plans");
  };
  const handleChange = (e) => {
    const value = e.target.value;
    setAddStudents(value === "yes");
    if (infoConfirmed) {
      //if the user changes to their answer, we need to reset stripe.
      setInfoConfirmed(false);
    }
  };

  useEffect(() => {
    setAnnualTotal(getTeacherProTotal(students));
  }, [students, setAnnualTotal]);

  useEffect(() => {
    if (!infoConfirmed) {
      return;
    }
    const createPaymentIntent = async () => {
      try {
        const response = await api.post("/create-subscription", {
          product_id: TEACHER_PRO_PRODUCT_ID,
          students: JSON.stringify(students),
          total_amount: getMonthlyTotal(annualTotal),
          email,
          firstName,
          lastName,
          userId: user.uid,
          planTitle: "Teacher Pro",
          setup_future_usage: "off_session",
        });
        const data = response.data;
        setClientSecret(data.clientSecret);
        setSubscriptionId(data.subscriptionId);
      } catch (err) {
        console.error("Error creating subscription:", err);
      }
    };

    if (addStudents !== null) {
      createPaymentIntent();
    }
  }, [addStudents, students, infoConfirmed]);

  const options = {
    clientSecret,
    appearance: {
      theme: "stripe",
      variables: {
        colorPrimary: "#0F172A",
      },
    },
  };

  return (
    <BaseLayout>
      <div className="min-h-screen bg-gray-50 py-12">
        <div className="max-w-3xl mx-auto px-4">
          <div className="flex relative w-full">
            <Button
              className="absolute left-0 bg-transparent hover:bg-primary/15 transition-colors text-gray-600"
              onClick={handleExit}
            >
              <ChevronLeft />
            </Button>
            <div className="w-full text-center">
              <SubscriptionHeader plan="TEACHER-PRO" />
            </div>
          </div>
          <Card className="shadow-lg">
            <CardHeader className="border-b bg-gray-50/50 px-6 py-4">
              <h2 className="text-xl font-semibold text-gray-900">
                Account Information
              </h2>
              <FormComponents
                firstName={firstName}
                setFirstName={setFirstName}
                lastName={lastName}
                setLastName={setLastName}
                email={email}
                setEmail={setEmail}
                disabled={infoConfirmed}
              />
            </CardHeader>
            <CardContent className="p-6">
              <div className="space-y-6">
                <div className="space-y-4">
                  <h3 className="text-lg font-semibold text-gray-900">
                    Student Enrollment
                  </h3>
                  <div className="bg-gray-50 p-4 rounded-lg">
                    <p className="text-sm text-gray-600 mb-4">
                      Would you like to add students to your plan? (
                      {TEACHER_PRO_MAX_STUDENTS} student maximum)
                    </p>
                    <div className="flex gap-6 m-2">
                      <div className="flex items-center gap-2">
                        <Input
                          type="radio"
                          id="yes"
                          name="addStudents"
                          value="yes"
                          className="h-4 w-4"
                          onChange={handleChange}
                          disabled={infoConfirmed}
                        />
                        <Label htmlFor="yes" className="text-sm">
                          Yes
                        </Label>
                      </div>
                      <div className="flex items-center gap-2">
                        <Input
                          type="radio"
                          id="no"
                          name="addStudents"
                          value="no"
                          className="h-4 w-4"
                          onChange={handleChange}
                          disabled={infoConfirmed}
                        />
                        <Label htmlFor="no" className="text-sm">
                          No
                        </Label>
                      </div>
                    </div>
                    <p className="text-md font-medium text-primary">*Cost:</p>
                    <ul className="text-sm text-gray-900">
                      <li className="p-2">
                        ${TEACHER_PRO_VOLUME_PRICING[0]}/year per student when
                        you have 1-9 students total
                      </li>
                      <li className="p-2">
                        ${TEACHER_PRO_VOLUME_PRICING[1]}/year per student when
                        you have 10-14 students total
                      </li>
                      <li className="p-2">
                        ${TEACHER_PRO_VOLUME_PRICING[2]}/year per student when
                        you have 15-20 students total
                      </li>
                    </ul>
                    {addStudents && (
                      <div className="space-y-4 border-t pt-6">
                        <h4 className="text-md font-medium text-gray-900">
                          Add Students
                        </h4>
                        <StudentEmailList
                          students={students}
                          setStudents={setStudents}
                          disabled={
                            infoConfirmed ||
                            students.length >= TEACHER_PRO_MAX_STUDENTS
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>

                {addStudents !== null && (
                  <div className="flex items-center justify-between pt-6 border-t">
                    <div className="flex flex-col gap-2">
                      <ConfirmButton
                        handleSubmit={() => setInfoConfirmed(true)}
                        disabled={
                          addStudents === null ||
                          (addStudents && students.length < 1) ||
                          infoConfirmed
                        }
                      />

                      <div className="flex justify-between">
                        <div className="flex flex-col gap-2">
                          <p className="text-sm text-gray-600">
                            Total: ${annualTotal}/year
                          </p>
                          <p className="text-sm text-gray-600">
                            Billed As: ${getMonthlyTotal(annualTotal)}
                            /month
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="pt-2 mt-9 flex flex-col gap-2">
                      <div className="text-sm text-gray-600">
                        Total Students:{" "}
                        <span className="font-medium">
                          {addStudents ? students?.length || 0 : 0}
                        </span>
                      </div>
                      <div className="text-sm text-gray-600">
                        Additional cost:{" "}
                        <span className="font-medium">
                          {addStudents
                            ? `$${annualTotal - TEACHER_PRO_PRICE}`
                            : "$0"}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {!infoConfirmed && addStudents !== null && (
                  <p className="text-primary text-sm text-center">
                    Please carefully review your information before proceeding
                    to payment.{" "}
                  </p>
                )}
              </div>
              {infoConfirmed && (
                <div className="mt-6 border-t pt-6">
                  <h3 className="text-lg font-semibold mb-4">
                    Payment Information
                  </h3>
                  {clientSecret ? (
                    <Elements stripe={stripePromise} options={options}>
                      <PaymentForm
                        monthlyPayment={getMonthlyTotal(annualTotal)}
                        planTitle={"Teacher Pro"}
                        students={students}
                        email={email}
                        subscriptionId={subscriptionId}
                      />
                    </Elements>
                  ) : (
                    <FormSkeleton />
                  )}
                </div>
              )}
            </CardContent>
          </Card>
        </div>
      </div>
    </BaseLayout>
  );
};
export default TeacherProCheckout;
