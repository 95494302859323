import api from "../api";
import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { useEffect, useState } from "react";
import { auth } from "../components/shared/firebase";
import { useGoogleAuth } from "./useGoogleAuth";

const useAuth = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isNewUser, setIsNewUser] = useState(false);
  const { signInWithGoogle } = useGoogleAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        try {
          const token = await firebaseUser.getIdTokenResult(true);
          localStorage.setItem("authToken", token.token);

          // If it's a new user, don't fetch user data yet
          if (!isNewUser) {
            const response = await api.get("/auth/user");
            setUser({
              ...response.data.user,
              role: token.claims.role,
              paid: token.claims.paid
            });
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
          if (!isNewUser) {
            setUser(null);
            localStorage.removeItem("authToken");
          }
        }
      } else {
        setUser(null);
        localStorage.removeItem("authToken");
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [isNewUser]);

  const login = async (email, password) => {
    setLoading(true);
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const tokenResult = await userCredential.user.getIdTokenResult(true);
      localStorage.setItem("authToken", tokenResult.token);
      const response = await api.get("/auth/user");
      setUser({
        ...response.data.user,
        role: tokenResult.claims.role,
        paid: tokenResult.claims.paid
      });
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const signup = async (firstName, lastName, email, password, role) => {
    setLoading(true);
    setIsNewUser(true);
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const firebaseUser = userCredential.user;

      const token = await firebaseUser.getIdToken();
      localStorage.setItem("authToken", token);
      const response = await api.post("/auth/register", {
        firstName,
        lastName,
        email,
        role,
        uid: firebaseUser.uid,
      });

      await firebaseUser.getIdToken(true);
      const tokenResult = await firebaseUser.getIdTokenResult();

      setUser({
        ...response.data.user,
        role: tokenResult.claims.role,
        paid: tokenResult.claims.paid
      });
    } catch (error) {
      console.error("Signup error:", error);
      throw error;
    } finally {
      setLoading(false);
      setIsNewUser(false);
    }
  };

  const googleSignup = async (role) => {
    setLoading(true);
    setIsNewUser(true);
    try {
      const userInfo = await signInWithGoogle();
      const token = await auth.currentUser.getIdToken();
      localStorage.setItem("authToken", token);
      try {
        //it's possible the user signs up when they have an existing account. In that case, sign em in.
        const authResponse = await api.get("/auth/user");
        const tokenResult = await auth.currentUser.getIdTokenResult(true);
        setUser({
          ...authResponse.data.user,
          role: tokenResult.claims.role,
          paid: tokenResult.claims.paid
        });
        return;
      } catch (error) {
        if (error.response?.status === 404) {
          const response = await api.post("/auth/register", {
            firstName: userInfo.firstName,
            lastName: userInfo.lastName,
            email: userInfo.email,
            role,
            uid: userInfo.uid,
          });

          await auth.currentUser.getIdToken(true);
          const tokenResult = await auth.currentUser.getIdTokenResult();

          setUser({
            ...response.data.user,
            role: tokenResult.claims.role,
            paid: tokenResult.claims.paid,
          });
        } else {
          throw error;
        }
      }
    } catch (error) {
      console.error("Google signup error:", error);
      throw error;
    } finally {
      setLoading(false);
      setIsNewUser(false);
    }
  };

  const googleLogin = async () => {
    setLoading(true);
    let currentUser = null;
    try {
      const userInfo = await signInWithGoogle();
      currentUser = auth.currentUser;

      const tokenResult = await currentUser.getIdTokenResult(true);
      localStorage.setItem("authToken", tokenResult.token);
      try {
        const response = await api.get("/auth/user");
        setUser({
          ...response.data.user,
          role: tokenResult.claims.role,
          paid: tokenResult.claims.paid
        });
      } catch (error) {
        if (error.response?.status === 404) {
          if (currentUser) {
            await currentUser.delete();
          }
          await signOut(auth);
          localStorage.removeItem("authToken");
          const noAccountError = new Error("NO_ACCOUNT");
          noAccountError.code = "NO_ACCOUNT";
          throw noAccountError;
        }
        throw error;
      }
    } catch (error) {
      console.error("Google login error:", error);
      if (currentUser && error.code !== "NO_ACCOUNT") {
        try {
          await currentUser.delete();
        } catch (deleteError) {
          console.error("Error cleaning up Firebase user:", deleteError);
        }
        await signOut(auth);
        localStorage.removeItem("authToken");
      }
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const logout = async () => {
    setLoading(true);
    try {
      await api.post("/auth/logout");
      await signOut(auth);
      localStorage.removeItem("authToken");
      setUser(null);
    } catch (error) {
      console.error("Logout error:", error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return { user, loading, login, googleSignup, googleLogin, signup, logout };
};

export default useAuth;
