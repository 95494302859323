import { Button } from "../../ui/button.jsx";
import { ChevronLeft } from "lucide-react";
import { useNavigate } from "react-router-dom";

const BackHomeButton = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/homepage");
  };
  
  return (
    <Button
      onClick={handleClick}
      className="bg-transparent hover:bg-purple-200"
    >
      <ChevronLeft className="text-red-500" />
    </Button>
  );
};

export default BackHomeButton;
