import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth } from '../components/shared/firebase';

export const useGoogleAuth = () => {
  const provider = new GoogleAuthProvider();
  
  const signInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      
      const userInfo = {
        firstName: user.displayName?.split(' ')[0] || '',
        lastName: user.displayName?.split(' ').slice(1).join(' ') || '',
        email: user.email,
        uid: user.uid
      };
      
      return userInfo;
    } catch (error) {
      console.error("Google Sign In Error:", error);
      throw error;
    }
  };

  return { signInWithGoogle };
};
