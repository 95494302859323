import { Label } from "../../ui/label.jsx";
import { Input } from "../../ui/input.jsx";

const FormComponents = ({
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
  disabled
}) => {
  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="space-y-2">
          <Label className="text-sm font-medium">First Name</Label>
          <Input
            type="text"
            value={firstName}
            id="firstName"
            className="w-full"
            onChange={(e) => setFirstName(e.target.value)}
            disabled={disabled}
          />
        </div>
        <div className="space-y-2">
          <Label className="text-sm font-medium">Last Name</Label>
          <Input
            type="text"
            value={lastName}
            id="lastName"
            className="w-full"
            onChange={(e) => setLastName(e.target.value)}
            disabled={disabled}
          />
        </div>
      </div>

      <div className="space-y-2">
        <Label className="text-sm font-medium">Email Address</Label>
        <Input
          type="email"
          value={email}
          id="email"
          className="w-full"
          onChange={(e) => setEmail(e.target.value)}
          disabled
        />
      </div>
    </>
  );
};

export default FormComponents;
