import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ChatCard from "./ChatCard";
import { calculateIntegrityScore } from "./CheatingDetection/IntegrityScoreCalculator";
import InterfaceHeader from "./InterfaceHeader";
import { InterfaceService } from "./InterfaceService";
import QuestionCard from "./QuestionCard";
import AssignmentInterfaceSkeleton from "./Skeletons/AssignmentInterfaceSkeleton";
import SubmissionDialog from "./SubmissionDialog";
import useAssignmentInterface from "./useAssignmentInterface";
import useCheatingDetection from "./useCheatingDetection";
import { useUser } from "../../../Contexts/userContext";

const AssignmentInterface = ({
  assignment,
  initialAnswers,
  userId,
  loading,
}) => {
  const assignmentId = assignment.id;
  const chatLogId = `${userId}_${assignmentId}`;
  const [submitting, setSubmitting] = useState(false);
  const [saving, setSaving] = useState(false);
  const [sendingAnswers, setSendingAnswers] = useState(false);
  const [lastSavedAnswers, setLastSavedAnswers] = useState(initialAnswers);
  const { refreshUserInfo, updateSubmissions } = useUser();

  const navigate = useNavigate();

  const {
    questions,
    answers,
    setAnswers,
    currentQuestion,
    setCurrentQuestion,
    currentIndex,
    setCurrentIndex,
  } = useAssignmentInterface(assignment, initialAnswers);

  useEffect(() => {
    const saveInterval = setInterval(() => {
      setLastSavedAnswers((prevLastSavedAnswers) => {
        if (answers !== prevLastSavedAnswers) {
          saveVersionHistory();
          InterfaceService.saveProgress(userId, assignmentId, answers);
          updateSubmissions(assignmentId, answers);
          return answers;
        }
        return prevLastSavedAnswers;
      });
    }, 60 * 1000);

    return () => clearInterval(saveInterval);
  }, [answers, updateSubmissions]);

  const { handleCutCopy, handlePaste } = useCheatingDetection(
    userId,
    assignmentId,
    currentQuestion?.id
  );

  const handleNextQuestion = () => {
    setCurrentIndex((prevIndex) => prevIndex + 1);
  };

  const handlePreviousQuestion = () => {
    setCurrentIndex((prevIndex) => prevIndex - 1);
  };

  const saveVersionHistory = async () => {
    await InterfaceService.saveVersionHistory(
      userId,
      assignmentId,
      questions,
      answers
    );
  };

  const onSaveAndSubmit = async () => {
    setSendingAnswers(true);
    try {
      await saveVersionHistory();
      const integrityReport = await calculateIntegrityScore(
        userId,
        assignmentId,
        questions,
        answers
      );
      await InterfaceService.saveAndSubmit(
        userId,
        assignmentId,
        answers,
        integrityReport
      );

      await InterfaceService.autograde(
        userId,
        assignmentId,
        questions,
        answers
      );

      await refreshUserInfo();

      navigate(-1);
    } catch (error) {
      console.error(error);
    } finally {
      setSendingAnswers(false);
    }
  };

  const onSaveAndExit = async () => {
    setSaving(true);
    setSendingAnswers(true);
    try {
      await saveVersionHistory();

      await InterfaceService.saveProgress(userId, assignmentId, answers);

      await refreshUserInfo();

      navigate(-1);
    } catch (error) {
      console.error(error);
    } finally {
      setSendingAnswers(false);
      setSaving(false);
    }
  };

  if (questions.length === 0) {
    return <div>No questions found for this assignment</div>;
  }

  if (loading || !assignment || !initialAnswers) {
    return <AssignmentInterfaceSkeleton />;
  }

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
      <main className="flex-1 flex flex-col">
        <div className="max-w-7xl mx-auto w-full flex-1 flex flex-col py-6 sm:px-6 lg:px-8">
          <InterfaceHeader
            saving={saving}
            sendingAnswers={sendingAnswers}
            onSaveAndExit={onSaveAndExit}
            setSubmitting={setSubmitting}
            currentIndex={currentIndex + 1}
            numberOfQuestions={questions.length}
          />

        <div className="flex gap-6 h-[800px] mt-6">
            <QuestionCard
              currentQuestion={currentQuestion}
              currentIndex={currentIndex}
              questionsLength={questions.length}
              answers={answers}
              setAnswers={setAnswers}
              assignmentId={assignmentId}
              userId={userId}
              handleCutCopy={handleCutCopy}
              handlePaste={handlePaste}
              handleNextQuestion={handleNextQuestion}
              handlePreviousQuestion={handlePreviousQuestion}
            />

            <ChatCard
              currentQuestion={currentQuestion}
              chatLogId={chatLogId}
              saving={saving}
              submitting={submitting}
            />
          </div>
        </div>
      </main>

      <SubmissionDialog
        isOpen={submitting || (!saving && sendingAnswers)}
        onOpenChange={setSubmitting}
        onSaveAndSubmit={onSaveAndSubmit}
        sendingAnswers={sendingAnswers}
      />
    </div>
  );
};

export default AssignmentInterface;
