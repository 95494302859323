import { cn } from "../../shared/utils/utils";
import { formatContent } from "../../shared/utils/utils";

const ChatMessage = ({ type, content, timestamp, studentName }) => {
  const messageDate = new Date(timestamp);

  return (
    <div
      className={cn(
        "p-3 rounded-lg shadow-sm max-w-[80%]",
        type === "user"
          ? "bg-primary/15 text-primary-foreground ml-auto"
          : "bg-secondary/15 text-secondary-foreground mr-auto"
      )}
    >
      <div className="flex justify-between items-center mb-1">
        <span
          className={cn(
            "font-medium text-sm",
            type === "user" ? "text-primary" : "text-secondary"
          )}
        >
          {type === "user" ? "You" : "Sparky"}
        </span>
        <span className="text-xs text-muted-foreground">
          {messageDate.toLocaleString(undefined, {
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
          })}
        </span>
      </div>
      <div className="text-sm text-foreground">{formatContent(content)}</div>
    </div>
  );
};

export default ChatMessage;
